import { ApiPath } from "../variables/ApiPath";
import { ApiClient } from "../utils/ApiClient";
import { CreateCollectionRequest } from "./request/CollectionRequest";
import { AxiosResponse } from "axios";

export class CollectionRepository {
  public static createData = (
    body: CreateCollectionRequest
  ): Promise<AxiosResponse> => {
    return ApiClient.Post(ApiPath.CREATE_COLLECTION, [], body);
  };

  public static updateData = (
    id: string,
    body: CreateCollectionRequest
  ): Promise<AxiosResponse> => {
    return ApiClient.Put(ApiPath.UPDATE_COLLECTION, [id], body);
  };
}
