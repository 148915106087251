import React, { useState } from "react";
import { Autocomplete as MaterialAutocomplete } from "@material-ui/lab";
import { OptionProps } from "./Select";
import { CircularProgress, TextField } from "@material-ui/core";
import { InputFieldProps } from "../models/InputFieldProps";
import { ApiClient } from "../utils/ApiClient";
var timeout: ReturnType<typeof setTimeout>;
const Autocomplete = (props: InputFieldProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState<OptionProps[]>([]);

  const [text, setText] = useState("");

  return (
    <MaterialAutocomplete
      disableClearable
      inputValue={text}
      onInputChange={(e, v) => {
        setText(v);
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          if (v.length > 0) {
            setLoading(true);
            ApiClient.Get(props.async!.url, [], { text: v }).then((res) => {
              setOptions(
                res.data.map((d: any) => ({
                  id: d[props.async!.valueKey],
                  text: d[props.async!.textKey],
                }))
              );
              setLoading(false);
            });
          }
        }, 150);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => option.text}
      options={options}
      onChange={(e, value) => {
        props.onChange(props.name, (value as any)[props.async!.valueKey]);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          // variant={"outlined"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default Autocomplete;
