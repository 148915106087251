import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import Input from "../Input";
import SnackbarUtils from "../../utils/SnackbarUtils";
import { useDispatch } from "react-redux";
import { getTableData } from "../../redux/actions/TableAction";
import { setLoading } from "../../redux/actions/LoadingAction";
import { CreateCouponRequest } from "../../models/request/CouponRequest";
import { CouponRepository } from "../../models/CouponRepository";
import InputField from "../InputField";

interface IProps {
  open: boolean;
  onClose: () => void;
  onChange: (name: string, value: string | number) => void;
  request: CreateCouponRequest;
}

const CreateCouponForm = ({ open, onClose, request, onChange }: IProps) => {
  const dispatch = useDispatch();

  const handleCreateCoupon = () => {
    dispatch(setLoading(true));
    CouponRepository.CreateCoupon(request)
      .then(() => {
        SnackbarUtils.success("Success creating coupon.");
        dispatch(getTableData());
        onClose();
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Create Collection</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="code"
              label="Code"
              value={request.code}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="start_time"
              label="Start Date"
              type="date"
              value={request.start_time}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="end_time"
              label="End Date"
              type="date"
              value={request.end_time}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="quantity"
              label="Quantity"
              type="number"
              value={request.quantity.toString()}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="percent"
              label="Percentage"
              type="number"
              value={request.percent.toString()}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              name="idr"
              label="Max IDR Discount"
              type="number"
              value={request.idr.toString()}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              name="sgd"
              label="Max SGD Discount"
              type="number"
              value={request.sgd.toString()}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              name="usd"
              label="Max USD Discount"
              type="number"
              value={request.usd.toString()}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="primary" onClick={handleCreateCoupon}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCouponForm;
