import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import Input from "../Input";
import { CreateCollectionRequest } from "../../models/request/CollectionRequest";
import SnackbarUtils from "../../utils/SnackbarUtils";
import { CollectionRepository } from "../../models/CollectionRepository";
import { useDispatch } from "react-redux";
import { getTableData } from "../../redux/actions/TableAction";
import { setLoading } from "../../redux/actions/LoadingAction";
import InputField from "../InputField";

interface IProps {
  open: boolean;
  onClose: () => void;
  onChange: (name: string, value: string | number) => void;
  request: CreateCollectionRequest;
}

const CreateCollectionForm = ({ open, onClose, request, onChange }: IProps) => {
  const dispatch = useDispatch();
  const handleCreateCollection = () => {
    dispatch(setLoading(true));
    CollectionRepository.createData(request)
      .then(() => {
        SnackbarUtils.success("Success creating collection.");
        dispatch(getTableData());
        onClose();
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Create Collection</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="name"
              label="Name"
              value={request.name}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name={"image"}
              label={"Image"}
              type={"image"}
              value={request.image}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCreateCollection}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCollectionForm;
