import { ApiPath } from "../variables/ApiPath";
import { ApiClient } from "../utils/ApiClient";
import { AxiosResponse } from "axios";

export class ExcelController {
  public static Download = (
    ids: string[],
    date: string
  ): Promise<AxiosResponse> => {
    return ApiClient.Post(ApiPath.DOWNLOAD_EXCEL, [], {
      order_nos: ids,
      date: date,
    });
  };
}
