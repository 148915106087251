import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { ApiPath } from "../variables/ApiPath";
import { Button, Grid} from "@material-ui/core";
import {setLoading} from "../redux/actions/LoadingAction";
import Input from "../components/Input";
import {ApiClient} from "../utils/ApiClient";
import SnackbarUtils from "../utils/SnackbarUtils";

const ColorPage = () => {
  const dispatch = useDispatch();

    const [message, setMessage] = useState('');

    useEffect(() => {
        ApiClient.Get(ApiPath.GET_MESSAGE, [], {}).then(res => {
            setMessage(res.data.value)
        })
    }, []);


    return (
    <>
      <Grid container spacing={3}>
          <Grid item xs={5}>
              <Input
                  name="message"
                  label="Message"
                  value={message}
                  onChange={(name, value) => {
                      setMessage(value)
                  }}
              />
          </Grid>
          <Grid item>
              <Button variant="outlined" color="primary" onClick={() => {
                  dispatch(setLoading(true))
                  ApiClient.Post(ApiPath.UPDATE_MESSAGE, [], {
                      message
                  }).then(res=>{
                      SnackbarUtils.success("Success updating message")
                  }).finally(() => {
                      dispatch(setLoading(false))
                  })
              }}>Submit</Button>
          </Grid>
      </Grid>
    </>
  );
};

export default ColorPage;
