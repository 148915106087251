import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { ApiPath } from "../variables/ApiPath";
import { Button, Grid} from "@material-ui/core";
import {setLoading} from "../redux/actions/LoadingAction";
import {ApiClient} from "../utils/ApiClient";
import SnackbarUtils from "../utils/SnackbarUtils";
import InputField from "../components/InputField";

const NewCollectionPage = () => {
  const dispatch = useDispatch();

    const [collectionId, setCollectionId] = useState("");
    const [collections, setCollections] = useState([]);

    useEffect(() => {
        ApiClient.Get(ApiPath.CUSTOMER_COLLECTION_LIST, [], {}).then(res => {
            setCollections(res.data.contents)
            ApiClient.Get(ApiPath.GET_NEW_COLLECTION, [], {}).then(res => {
                setCollectionId(res.data.value.id)
            })
        })

    }, []);

    return (
    <>
      <Grid container spacing={3}>
          <Grid item xs={5}>
              <InputField
                  name="collection_id"
                  value={collectionId.toString()}
                  label="Collection"
                  type="select"
                  onChange={(name, val) => {
                      setCollectionId(val)
                  }}
                  options={
                      collections.map(col => ({
                          value: (col as any).id.toString(),
                          text: (col as any).name
                      }))
                  }
              />
          </Grid>
          <Grid item>
              <Button variant="outlined" color="primary" onClick={() => {
                  dispatch(setLoading(true))
                  ApiClient.Post(ApiPath.UPDATE_NEW_COLLECTION, [], {
                      collection_id: collectionId
                  }).then(res=>{
                      SnackbarUtils.success("Success updating collection")
                  }).finally(() => {
                      dispatch(setLoading(false))
                  })
              }}>Submit</Button>
          </Grid>
      </Grid>
    </>
  );
};

export default NewCollectionPage;
