import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Input as MaterialInput,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Input from "../Input";
import Select from "../Select";
import { ApiPath } from "../../variables/ApiPath";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { CreateProductRequest } from "../../models/request/ProductRequest";

import { ProductRepository } from "../../models/ProductRepository";
import SnackbarUtils from "../../utils/SnackbarUtils";
import { numberToString, stringToNumber } from "../../utils/NumberUtils";
import { useDispatch } from "react-redux";
import { getTableData } from "../../redux/actions/TableAction";
import { ProductDetailResponse } from "../../models/response/ProductResponse";
import { setLoading } from "../../redux/actions/LoadingAction";

interface IProps {
  onChange: (name: string, value: string | number) => void;
  onAddImage: (value: any) => void;
  onDeleteImage: (index: number) => void;
  onAddSize: () => void;
  onDeleteSize: (index: number) => void;
  onSizeChange: (idx: number, name: string, value: number) => void;
  request: CreateProductRequest;
  open: boolean;
  onClose: () => void;
  item?: ProductDetailResponse;
}

const CreateProductForm = ({
  onChange,
  onAddImage,
  onDeleteImage,

  onSizeChange,
  request,
  open,
  onClose,
  item,
}: IProps) => {
  const dispatch = useDispatch();

  const handleArchiveUnarchive = () => {
    if (item !== undefined) {
      dispatch(setLoading(true));
      ProductRepository.DeleteProduct(item.id).then(() => {
        SnackbarUtils.success(
          "Success " +
            (item.deleted_at === null ? "archiving" : "unarchiving") +
            " product."
        );
        dispatch(getTableData());
        onClose();
      });
    }
  };

  const handleUpdateProduct = () => {
    if (item !== undefined) {
      dispatch(setLoading(true));
      ProductRepository.UpdateProduct(item.id, request).then(() => {
        SnackbarUtils.success("Success updating product.");
        dispatch(getTableData());
        onClose();
      });
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>Update Product</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="name"
              label="Name"
              value={request.name}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="sku"
              label="SKU"
              value={request.sku}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              name="product_type_id"
              label="Product Type"
              value={request.product_type_id.toString()}
              onChange={onChange}
              async={{
                url: ApiPath.PRODUCT_TYPE_LIST,
                textKey: "name",
                valueKey: "id",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              name="collection_id"
              label="Collection"
              value={request.collection_id.toString()}
              onChange={onChange}
              async={{
                url: ApiPath.COLLECTIONS,
                textKey: "name",
                valueKey: "id",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="color"
              label="Color"
              value={request.color}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              multiline={true}
              name="description"
              label="Description"
              value={request.description}
              onChange={onChange}
            />
          </Grid>
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={4}>
              <Input
                name="idr"
                label="IDR"
                value={request.idr.toString()}
                type="number"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                name="sgd"
                label="SGD"
                value={request.sgd.toString()}
                onChange={onChange}
                type="number"
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                name="usd"
                label="USD"
                value={request.usd.toString()}
                onChange={onChange}
                type="number"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            {request.images.map((image, idx) => (
              <Grid item xs={2}>
                <Box position={"relative"} border={"2px solid lightgray"}>
                  <img
                    src={image}
                    alt={"shoes"}
                    style={{
                      width: "100%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                  />
                  <Box position={"absolute"} top={0} right={0}>
                    <IconButton
                      style={{ color: "red" }}
                      onClick={() => {
                        onDeleteImage(idx);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            ))}
            <Grid item xs={2}>
              <Box position={"relative"} border={"2px solid lightgray"}>
                <input
                  onChange={onAddImage}
                  type="file"
                  hidden={true}
                  id="image-input"
                  accept="image/*"
                  multiple={true}
                />
                <label
                  htmlFor="image-input"
                  style={{
                    width: "100%",
                    aspectRatio: "1/1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AddIcon />
                </label>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MaterialTable>
              <TableHead>
                <TableRow>
                  <TableCell>Size</TableCell>
                  <TableCell>Stock</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {request.sizes.map((size, idx) => (
                  <TableRow>
                    <TableCell>
                      {size.size}
                      {/*<MaterialInput*/}
                      {/*  onChange={(e) => {*/}
                      {/*    onSizeChange(*/}
                      {/*      idx,*/}
                      {/*      "size",*/}
                      {/*      stringToNumber(e.currentTarget.value)*/}
                      {/*    );*/}
                      {/*  }}*/}
                      {/*  value={numberToString(size.size)}*/}
                      {/*/>*/}
                    </TableCell>
                    <TableCell>
                      <MaterialInput
                        onChange={(e) => {
                          onSizeChange(
                            idx,
                            "stock",
                            stringToNumber(e.currentTarget.value)
                          );
                        }}
                        value={numberToString(size.stock)}
                      />
                    </TableCell>
                    {/*<TableCell>*/}
                    {/*  <IconButton*/}
                    {/*    onClick={() => {*/}
                    {/*      onDeleteSize(idx);*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    <CloseIcon />*/}
                    {/*  </IconButton>*/}
                    {/*</TableCell>*/}
                  </TableRow>
                ))}
              </TableBody>
            </MaterialTable>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleArchiveUnarchive}
        >
          {item !== undefined && item.deleted_at === null
            ? "Archive"
            : "Unarchive"}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleUpdateProduct}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProductForm;
