import React, { Component } from "react";
import { TextField } from "@material-ui/core";
import { numberToString, stringToNumber } from "../utils/NumberUtils";

interface IProps {
  name: string;
  label: string;
  value: string;
  onChange: (name: string, value: any) => void;
  type?: "number" | "text" | "email" | "password";
  multiline?: boolean;
}

class Input extends Component<IProps> {
  render() {
    return (
      <TextField
        multiline={this.props.multiline}
        type={
          this.props.type === "email"
            ? "email"
            : this.props.type === "password"
            ? "password"
            : "text"
        }
        fullWidth
        name={this.props.name}
        value={
          this.props.type === "number"
            ? numberToString(parseInt(this.props.value))
            : this.props.value
        }
        label={this.props.label}
        placeholder={this.props.label}
        onChange={(e) => {
          this.props.onChange(
            this.props.name,
            this.props.type === "number"
              ? stringToNumber(e.currentTarget.value)
              : e.currentTarget.value
          );
        }}
      />
    );
  }
}

export default Input;
