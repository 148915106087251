import React, { useState } from "react";
import { ApiPath } from "../variables/ApiPath";
import Table from "../components/table/Table";
import { generateHeadCell } from "../models/table/TablePropsGenerator";
import { generateTableFilterField } from "../models/table/FilterProps";
import { UserListResponse } from "../models/user/UserResponse";
import UpdateCustomerDialog from "../components/customer/UpdateCustomerDialog";
import { UpdateUserRequest } from "../models/user/UserRequest";
import { setLoading } from "../redux/actions/LoadingAction";
import { useDispatch } from "react-redux";

const UserPage = () => {
  const [selectedUser, setSelectedUser] = useState<UserListResponse>();
  const [updateRequest, setUpdateRequest] = useState<UpdateUserRequest>({
    email: "",
    name: "",
  });

  const dispatch = useDispatch();
  const fields = [
    generateHeadCell("name", "Name", "text", true),
    generateHeadCell("email", "Email", "text", true),
    generateHeadCell("status", "Status", "text", true),
    generateHeadCell("role", "Role", "text", true),
  ];

  const filterFields = [
    generateTableFilterField("name", "Name", "text"),
    generateTableFilterField("email", "Email", "text"),
    generateTableFilterField(
      "status_id",
      "Status",
      "select",
      [
        { text: "Active", value: "1" },
        { text: "Inactive", value: "2" },
      ],
      undefined,
      "1"
    ),
    generateTableFilterField(
      "role_id",
      "Role",
      "select",
      [
        { text: "Customer", value: "2" },
        { text: "Admin", value: "1" },
      ],
      undefined,
      "2"
    ),
  ];

  return (
    <>
      <UpdateCustomerDialog
        open={selectedUser !== undefined}
        onClose={() => {
          setSelectedUser(undefined);
          setUpdateRequest({
            email: "",
            name: "",
          });
          dispatch(setLoading(false));
        }}
        onChange={(name, value) => {
          setUpdateRequest({
            ...updateRequest,
            [name]: value,
          });
        }}
        request={updateRequest}
        data={selectedUser}
      />
      <Table
        title={"Users"}
        headCells={fields}
        filterFields={filterFields}
        onRowClick={(e: UserListResponse) => {
          setSelectedUser(e);
          setUpdateRequest({
            name: e.name,
            email: e.email,
          });
        }}
        url={ApiPath.GET_USER_LIST}
      />
    </>
  );
};

export default UserPage;
