import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { PaginationResponse } from "../../models/BasicResponse";
import { SortProps, PaginationProps } from "../../models/table/TableProps";
import { ReduxState } from "../ReduxStore";
import { ApiClient } from "../../utils/ApiClient";
import { AxiosResponse } from "axios";
import { setLoading } from "./LoadingAction";

export const SET_TABLE_DATA = "SET_TABLE_DATA";
export const SET_PAGINATION = "SET_PAGINATION";
export const SET_SORT = "SET_SORT";
export const SET_GET_URL = "SET_GET_URL";
export const SET_FILTER = "SET_FILTER";
export const RESET_FILTER = "RESET_FILTER";
export const SET_TABLE_ROW_CHECKED = "SET_TABLE_ROW_CHECKED";
export const SET_MULTIPLE_ROW_CHECKED = "SET_MULTIPLE_ROW_CHECKED";

export type TableActionTypes =
  | {
      type: typeof SET_TABLE_DATA;
      payload: PaginationResponse<any>;
    }
  | {
      type: typeof SET_PAGINATION;
      payload: PaginationProps;
    }
  | {
      type: typeof SET_SORT;
      payload: SortProps;
    }
  | {
      type: typeof SET_GET_URL;
      payload: string;
    }
  | {
      type: typeof SET_FILTER;
      payload: any;
    }
  | {
      type: typeof RESET_FILTER;
    }
  | {
      type: typeof SET_TABLE_ROW_CHECKED;
      payload: any;
    }
  | {
      type: typeof SET_MULTIPLE_ROW_CHECKED;
      payload: any;
    };

export const setTableData = (
  payload: PaginationResponse<any>
): TableActionTypes => ({
  type: SET_TABLE_DATA,
  payload: payload,
});

export const setPagination = (payload: PaginationProps): TableActionTypes => ({
  type: SET_PAGINATION,
  payload: payload,
});

export const setSort = (payload: SortProps): TableActionTypes => ({
  type: SET_SORT,
  payload: payload,
});

export const setGetUrl = (url: string) => ({
  type: SET_GET_URL,
  payload: url,
});

export const setFilter = (payload: any) => ({
  type: SET_FILTER,
  payload: payload,
});

export const resetFilter = () => ({
  type: RESET_FILTER,
});

export const setTableRowChecked = (payload: any) => {
  return {
    type: SET_TABLE_ROW_CHECKED,
    payload: payload,
  };
};

export const setMultipleRowChecked = (payload: any) => {
  return {
    type: SET_MULTIPLE_ROW_CHECKED,
    payload: payload,
  };
};

export const getTableData =
  (): ThunkAction<void, ReduxState, {}, Action> => (dispatch, getState) => {
    const { tableFilter, pagination, sort, getTableDataUrl } = (
      getState() as any
    ).TableReducer;
    dispatch(setLoading(true));
    ApiClient.Post(getTableDataUrl, [], {
      filter: tableFilter,
      pagination: pagination,
      sort: sort,
    }).then((res: AxiosResponse<PaginationResponse<any>>) => {
      dispatch(setTableData(res.data));
      dispatch(setLoading(false));
    });
  };

export const getTableDataWithFilter =
  (filter: any): ThunkAction<void, ReduxState, {}, Action> =>
  (dispatch, getState) => {
    const { pagination, sort, getTableDataUrl } = (getState() as any)
      .TableReducer;
    dispatch(setLoading(true));
    dispatch(setFilter(filter));
    ApiClient.Post(getTableDataUrl, [], {
      filter: filter,
      pagination: pagination,
      sort: sort,
    }).then((res: AxiosResponse<PaginationResponse<any>>) => {
      dispatch(setTableData(res.data));
      dispatch(setLoading(false));
    });
  };

export const getTableDataWithUrlAndFilter =
  (url: string, filter: any): ThunkAction<void, ReduxState, {}, Action> =>
  (dispatch, getState) => {
    const { pagination, sort } = (getState() as any).TableReducer;
    dispatch(setLoading(true));
    dispatch(setGetUrl(url));
    dispatch(setFilter(filter));
    ApiClient.Post(url, [], {
      filter: filter,
      pagination: pagination,
      sort: sort,
    }).then((res: AxiosResponse<PaginationResponse<any>>) => {
      dispatch(setTableData(res.data));
      dispatch(setLoading(false));
    });
  };
