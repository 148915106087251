import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import Input from "../Input";
import InputField from "../InputField";
import { CreateCollectionRequest } from "../../models/request/CollectionRequest";

interface IProps {
  open: boolean;
  onClose: () => void;
  onChange: (name: string, value: string | number) => void;
  request: CreateCollectionRequest;
  handleUpdate: () => void;
}

const UpdateCollectionForm = ({
  open,
  request,
  onClose,
  onChange,
  handleUpdate,
}: IProps) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Update Shipping Rate</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="name"
              label="Name"
              value={request.name}
              onChange={(name, value) => {
                onChange(name, value);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <InputField
              name={"image"}
              label={"Image"}
              type={"image"}
              value={request.image}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="primary" onClick={handleUpdate}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCollectionForm;
