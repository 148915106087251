import React, { Component } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MaterialSelect,
} from "@material-ui/core";
import { ApiClient } from "../utils/ApiClient";
import { AxiosResponse } from "axios";
import { ListResponse } from "../models/BasicResponse";

interface IProps {
  name: string;
  label: string;
  value: string;
  onChange: (name: string, value: string) => void;
  options?: OptionProps[];
  async?: AsyncProps;
}

export interface AsyncProps {
  url: string;
  valueKey: string;
  textKey: string;
}

export interface OptionProps {
  text: string;
  value: number | string;
}

interface IState {
  options: OptionProps[];
}

class Select extends Component<IProps, IState> {
  state: IState = {
    options: [],
  };

  componentWillMount() {
    if (this.props.async !== undefined) {
      ApiClient.Get(this.props.async.url, [], {}).then(
        (res: AxiosResponse<ListResponse<any>>) => {
          const data = res.data.contents.map((content: any) => ({
            value: content[this.props.async!.valueKey!],
            text: content[this.props.async!.textKey!],
          }));
          this.setState({
            options: [...data],
          });
        }
      );
    }
  }

  render() {
    return (
      <FormControl fullWidth>
        <InputLabel>{this.props.label}</InputLabel>
        <MaterialSelect
          value={this.props.value}
          onChange={(e: any) => {
            this.props.onChange(this.props.name, e.target.value.toString());
          }}
        >
          <MenuItem value="">
            <em>Show All</em>
          </MenuItem>
          {this.props.async !== undefined &&
            this.state.options.map((option) => (
              <MenuItem value={option.value.toString()}>{option.text}</MenuItem>
            ))}
          {this.props.options !== undefined &&
            this.props.options.map((option) => (
              <MenuItem value={option.value.toString()}>{option.text}</MenuItem>
            ))}
        </MaterialSelect>
      </FormControl>
    );
  }
}

export default Select;
