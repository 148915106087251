import React, { useState } from "react";
import Table from "../components/table/Table";
import { generateHeadCell } from "../models/table/TablePropsGenerator";
import { generateTableFilterField } from "../models/table/FilterProps";
import { ApiPath } from "../variables/ApiPath";
import { Button } from "@material-ui/core";
import {
  CreateCouponRequest,
  UpdateCouponRequest,
} from "../models/request/CouponRequest";
import CreateCouponForm from "../components/coupon/CreateCouponForm";
import CouponDetailForm from "../components/coupon/CouponDetailForm";
import { CouponResponse } from "../models/response/CouponResponse";
const moment = require("moment");
const CouponPage = () => {
  const [selectedCoupon, setSelectedCoupon] = useState<CouponResponse>({
    code: "",
    end_time: "",
    id: "",
    idr: 0,
    percent: 0,
    quantity: 0,
    sgd: 0,
    start_time: "",
    usd: 0,
  });
  const [createCouponRequest, setCreateCouponRequest] =
    useState<CreateCouponRequest>({
      code: "",
      end_time: moment(),
      idr: 0,
      sgd: 0,
      start_time: moment(),
      usd: 0,
      percent: 0,
      quantity: 0,
    });

  const [updateRequest, setUpdateRequest] = useState<UpdateCouponRequest>({
    end_time: moment(),
  });

  const handleRequestChange = (name: string, value: any) => {
    setCreateCouponRequest({
      ...createCouponRequest,
      [name]: value,
    });
  };

  const [creatingCoupon, setCreatingCoupon] = useState(false);

  const fields = [
    generateHeadCell("code", "Code", "text", true),
    generateHeadCell("start_time", "Start Time", "text", true),
    generateHeadCell("end_time", "End Time", "text", true),
    generateHeadCell("quantity", "Total Quota", "text", true),
    generateHeadCell("used", "Used", "text", true),
  ];

  const filter_fields = [generateTableFilterField("code", "Code", "text")];
  return (
    <>
      <CouponDetailForm
        request={updateRequest}
        open={selectedCoupon.code !== ""}
        onClose={() => {
          setSelectedCoupon({
            code: "",
            end_time: "",
            id: "",
            idr: 0,
            percent: 0,
            quantity: 0,
            sgd: 0,
            start_time: "",
            usd: 0,
          });
        }}
        onChange={(name, value) => {
          setUpdateRequest({
            ...updateRequest,
            [name]: value,
          });
        }}
        data={selectedCoupon}
      />
      <CreateCouponForm
        open={creatingCoupon}
        onClose={() => {
          setCreatingCoupon(false);
          setCreateCouponRequest({
            code: "",
            percent: 0,
            end_time: moment(),
            idr: 0,
            sgd: 0,
            start_time: moment(),
            usd: 0,
            quantity: 0,
          });
        }}
        onChange={handleRequestChange}
        request={createCouponRequest}
      />
      <Table
        headActions={[
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setCreatingCoupon(true)}
          >
            Create Coupon
          </Button>,
        ]}
        title={"Coupons"}
        headCells={fields}
        filterFields={filter_fields}
        onRowClick={(e) => {
          setUpdateRequest({
            end_time: moment(new Date(e.end_time)),
          });
          setSelectedCoupon(e);
        }}
        url={ApiPath.GET_COUPONS}
      />
    </>
  );
};

export default CouponPage;
