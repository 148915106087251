import { ThunkAction } from "redux-thunk";
import { UserResponse } from "../../models/user/UserResponse";
import { Action } from "redux";
import { User } from "../../models/user";
import { ReduxState } from "../ReduxStore";
import { Constant } from "../../variables/Constant";
import { Config } from "../../ProjectConfig";

const cookies = require("react-cookies");

export const SET_USER = "SET_USER";

export type UserActionTypes = {
  type: typeof SET_USER;
  payload: UserResponse;
};

export const setUser = (payload: UserResponse): UserActionTypes => {
  return {
    type: SET_USER,
    payload: payload,
  };
};

export const getUser =
  (): ThunkAction<void, ReduxState, unknown, Action> => (dispatch) => {
    if (cookies.load(Constant.ACCESS_TOKEN) !== undefined) {
      User.getCurrentUser()
        .then((res) => {
          if (window.location.pathname === "/login") {
            window.location.href = Config.defaultUrl;
          }
          dispatch(setUser(res.data.value));
        })
        .catch((err) => {
          if (window.location.pathname !== "/login") {
            cookies.remove(Constant.ACCESS_TOKEN);
            window.location.href = "/login";
          }
        });
    } else {
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    }
  };

export const logout =
  (): ThunkAction<void, ReduxState, unknown, Action> => (dispatch) => {
    if (cookies.load(Constant.ACCESS_TOKEN) !== undefined) {
      User.logout().finally(() => {
        cookies.remove(Constant.ACCESS_TOKEN);
        if (window.location.pathname !== "/login") {
          window.location.href = "/login";
        }
      });
    } else {
      cookies.remove(Constant.ACCESS_TOKEN);
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    }
  };
