import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { PaginationResponse } from "../models/BasicResponse";
import { SortProps, PaginationProps } from "../models/table/TableProps";
import RootReducer from "./reducers/RootReducer";
import { ITableReducer } from "./reducers/TableReducer";
import { IUserReducer } from "./reducers/UserReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import { ILoadingReducer } from "./reducers/LoadingReducer";
export interface ReduxReducerState {
  TableReducer: ITableReducer;
  UserReducer: IUserReducer;
  LoadingReducer: ILoadingReducer;
}

export interface ReduxState {
  tableData: PaginationResponse<any>;
  pagination: PaginationProps;
  sort: SortProps;
  getTableDataUrl: string;
  tableFilter: any;
}

const store = createStore(
  RootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
