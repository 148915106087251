export interface AsyncProps {
  url: string;
  valueKey: string;
  textKey: string;
}

export interface OptionProps {
  text: string;
  value: number | string;
}

export interface FilterFieldProps {
  name: string;
  label: string;
  type:
    | "number"
    | "text"
    | "email"
    | "date"
    | "select"
    | "multiline"
    | "image"
    | "autocomplete"
    | "numeric"
    | "multiple-autocomplete";
  options?: OptionProps[];
  async?: AsyncProps;
  initialValue?: string | null;
}

export const generateTableFilterField = (
  name: string,
  label: string,
  type:
    | "number"
    | "text"
    | "email"
    | "date"
    | "select"
    | "multiline"
    | "image"
    | "autocomplete"
    | "numeric"
    | "multiple-autocomplete",
  options?: OptionProps[],
  async?: AsyncProps,
  initialValue?: string | null
) => ({
  name: name,
  label: label,
  type: type,
  options: options,
  async: async,
  initialValue: initialValue,
});
