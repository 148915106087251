import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, logout } from "./redux/actions/UserAction";
import { Config } from "./ProjectConfig";
import SnackbarUtils from "./utils/SnackbarUtils";
import { useSnackbar } from "notistack";
import { ReduxReducerState } from "./redux/ReduxStore";
import { UserResponse } from "./models/user/UserResponse";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import OrderDetailPage from "./page/OrderDetailPage";
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotFound from "./components/NotFound";
import InvoicePage from "./page/InvoicePage";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  login: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: 20000000,
    color: "#fff",
  },
}));

export default function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    SnackbarUtils.setSnackBar(enqueueSnackbar, closeSnackbar);

    dispatch(getUser());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const user = useSelector<ReduxReducerState, UserResponse>(
    (state) => state.UserReducer.user
  );

  const isLoading = useSelector<ReduxReducerState, boolean>(
    (state) => state.LoadingReducer.loading
  );

  const hasUser = useSelector<ReduxReducerState, boolean>(
    (state) => state.UserReducer.hasUser
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderProfileMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={anchorEl !== null}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            window.location.href = "/account";
            handleMenuClose();
          }}
        >
          My account
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(logout());
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Switch>
          <Route path="/print/:id" component={InvoicePage} />
          <Route>
            <div className={classes.root}>
              <CssBaseline />
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                  <Typography
                    variant="h6"
                    noWrap
                    className={classes.title}
                    style={{ color: "white" }}
                  >
                    {Config.projectName}
                  </Typography>

                  {hasUser ? (
                    <IconButton
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget as any);
                      }}
                      style={{ color: "white" }}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                  ) : (
                    <Link to="/login">
                      <Button color="inherit">Login</Button>
                    </Link>
                  )}
                  {renderProfileMenu()}
                </Toolbar>
              </AppBar>
              {user.id !== "" && (
                <Drawer
                  className={classes.drawer}
                  variant="permanent"
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  <Toolbar />
                  <div className={classes.drawerContainer}>
                    <List>
                      {Config.navLinks.map((link, index) => (
                        <ListItem
                          button
                          key={link.text}
                          component="a"
                          href={link.href}
                        >
                          <ListItemText primary={link.text} />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </Drawer>
              )}
              <main
                className={user.id !== "" ? classes.content : classes.login}
              >
                <Toolbar />
                <Switch>
                  {Config.routes.map((route) => (
                    <Route exact path={route.url} component={route.component} />
                  ))}
                  <Route
                    exact
                    path={"/orders/:id"}
                    component={OrderDetailPage}
                  />
                  <Route component={NotFound}></Route>
                </Switch>
              </main>
            </div>
          </Route>
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
  );
}
