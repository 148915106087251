export const SET_LOADING = "SET_LOADING";

export type LoadingActionType = {
  type: typeof SET_LOADING;
  payload: boolean;
};

export const setLoading = (payload: boolean): LoadingActionType => {
  return {
    type: SET_LOADING,
    payload: payload,
  };
};
