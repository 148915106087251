import { Constant } from "./Constant";

export const ApiPath = {
  PRODUCT_TYPE_LIST: Constant.API_URL + "/admin/product-types",
  PRODUCT_LIST: Constant.API_URL + "/admin/products/getProductList",
  CREATE_PRODUCT: Constant.API_URL + "/admin/products",
  PRODUCT_DETAIL: Constant.API_URL + "/admin/products/detail/{}",
  UPDATE_PRODUCT: Constant.API_URL + "/admin/products/{}",
  DELETE_PRODUCT: Constant.API_URL + "/admin/products/{}",

  COLLECTIONS: Constant.API_URL + "/admin/collections",
  COLLECTION_LIST: Constant.API_URL + "/admin/collections/getCollectionList",
  CREATE_COLLECTION: Constant.API_URL + "/admin/collections",
  UPDATE_COLLECTION: Constant.API_URL + "/admin/collections/{}",

  UPLOAD_IMAGE: Constant.API_URL + "/admin/images",

  ORDER_LIST: Constant.API_URL + "/admin/order/getOrderList",
  ORDER_DETAIL: Constant.API_URL + "/admin/order/{}/detail",
  PROCESS_ORDER: Constant.API_URL + "/admin/order/{}/process",
  PACK_ORDER: Constant.API_URL + "/admin/order/{}/pack",
  PICKUP_ORDER: Constant.API_URL + "/admin/order/{}/pickup",
  SEND_ORDER: Constant.API_URL + "/admin/order/{}/send",
  CANCEL_ORDER: Constant.API_URL + "/admin/order/{}/cancel",

  LOGIN: Constant.API_URL + "/admin/auth/login",
  LOGOUT: Constant.API_URL + "/auth/logout",
  CURRENT_USER: Constant.API_URL + "/user",
  UPDATE_ACCOUNT: Constant.API_URL + "/auth/update",
  UPDATE_USER: Constant.API_URL + "/admin/users/{}",
  ACTIVE_INACTIVE: Constant.API_URL + "/admin/users/{}",
  PROMOTE_DEMOTE: Constant.API_URL + "/admin/users/{}/promote-demote",

  GET_USER_LIST: Constant.API_URL + "/admin/users/getUserList",

  GET_SHIPPING_RATE:
    Constant.API_URL + "/admin/shipping-rates/getShippingRateList",
  UPDATE_SHIPPING_RATE: Constant.API_URL + "/admin/shipping-rates/{}",

  GET_COUPONS: Constant.API_URL + "/admin/coupon/getCouponList",
  CREATE_COUPON: Constant.API_URL + "/admin/coupon/createCoupon",
  UPDATE_COUPON: Constant.API_URL + "/admin/coupon/{}",
  DELETE_COUPON: Constant.API_URL + "/admin/coupon/{}",

  GET_PRODUCT_TYPE_LIST:
    Constant.API_URL + "/admin/product-types/getProductTypeList",
  CREATE_PRODUCT_TYPE: Constant.API_URL + "/admin/product-types/create",
  UPDATE_PRODUCT_TYPE: Constant.API_URL + "/admin/product-types/{}",

  GET_EVENTS: Constant.API_URL + "/admin/events/filter",
  CREATE_EVENT: Constant.API_URL + "/admin/events/store",
  SHOW_EVENT: Constant.API_URL + "/admin/events/{}",
  UPDATE_EVENT: Constant.API_URL + "/admin/events/{}",
  DELETE_EVENT: Constant.API_URL + "/admin/events/{}",

  DOWNLOAD_EXCEL: Constant.API_URL + "/admin/excel",

  GET_COLOR: Constant.API_URL + "/admin/colors/getColorList",
  STORE_COLOR: Constant.API_URL + "/admin/colors",
  DELETE_COLOR: Constant.API_URL + "/admin/colors/{}",

  GET_MESSAGE: Constant.API_URL + "/news",
  UPDATE_MESSAGE: Constant.API_URL + "/admin/news",

  GET_NEW_COLLECTION: Constant.API_URL + "/new-collection",
  UPDATE_NEW_COLLECTION: Constant.API_URL + "/admin/new-collection",
  CUSTOMER_COLLECTION_LIST: Constant.API_URL + "/collections",

};
