import React, { useState } from "react";
import Table from "../components/table/Table";
import { generateHeadCell } from "../models/table/TablePropsGenerator";
import { generateTableFilterField } from "../models/table/FilterProps";
import CreateProductForm from "../components/product/CreateProductForm";
import { CreateProductRequest } from "../models/request/ProductRequest";
import { ImageController } from "../models/ImageController";
import { ApiPath } from "../variables/ApiPath";
import { Button } from "@material-ui/core";
import UpdateProductForm from "../components/product/UpdateProductForm";
import { ProductRepository } from "../models/ProductRepository";
import { ProductDetailResponse } from "../models/response/ProductResponse";
import { setLoading } from "../redux/actions/LoadingAction";
import { useDispatch } from "react-redux";

const ProductPage = () => {
  const [selectedProduct, setSelectedProduct] =
    useState<ProductDetailResponse>();

  const dispatch = useDispatch();

  const [request, setRequest] = useState<CreateProductRequest>({
    name: "",
    idr: 0,
    sku: "",
    usd: 0,
    collection_id: -1,
    images: [],
    sgd: 0,
    color: "",
    description: "",
    product_type_id: -1,
    sizes: [
      { size: 36, stock: 0 },
      { size: 37, stock: 0 },
      { size: 38, stock: 0 },
      { size: 39, stock: 0 },
      { size: 40, stock: 0 },
    ],
  });

  const [creatingProduct, setCreatingProduct] = useState(false);

  const handleChangeAddRequest = (name: string, value: any) => {
    setRequest({
      ...request,
      [name]:
        (request as any)[name as any] instanceof Number
          ? parseInt(value, 10)
          : value,
    });
  };

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null && e.target.files[0] !== undefined) {
      const data = [] as string[];

      for (let i = 0; i < e.target.files.length; i++) {
        const x = e.target.files.length;
        dispatch(setLoading(true));
        ImageController.Upload(e.target.files[i])
          .then((res) => {
            data.push(res.data.value.relative_path);
          })
          .then(() => {
            setRequest({
              ...request,
              images: [...request.images, ...data],
            });
            if (i === x - 1) {
              dispatch(setLoading(false));
            }
          });
      }
    }
  };

  const handleDeleteImage = (idx: number) => {
    const images = request.images;
    images.splice(idx, 1);
    setRequest({
      ...request,
      images: [...images],
    });
  };

  const handleDeleteSize = (idx: number) => {
    const sizes = request.sizes;
    sizes.splice(idx, 1);
    setRequest({
      ...request,
      sizes: [...sizes],
    });
  };

  const handleAddSize = () => {
    setRequest({
      ...request,
      sizes: [
        ...request.sizes,
        {
          size: 0,
          stock: 0,
        },
      ],
    });
  };

  const handleSizeChange = (idx: number, name: string, value: number) => {
    const sizes = request.sizes;
    sizes[idx] = {
      ...sizes[idx],
      [name]: value,
    };

    setRequest({
      ...request,
      sizes: [...sizes],
    });
  };

  const fields = [
    generateHeadCell("image", "Image", "image", false),
    generateHeadCell("sku", "SKU", "text", true),
    generateHeadCell("name", "Name", "text", true),
    generateHeadCell("color", "Color", "text", true),
    generateHeadCell("collection", "Collection", "text", true),
    generateHeadCell("product_type", "Product Type", "text", true),
    generateHeadCell("idr", "IDR", "number", true),
    generateHeadCell("sgd", "SGD", "number", true),
  ];

  const filter_fields = [
    generateTableFilterField("name", "Name", "text"),
    generateTableFilterField("sku", "SKU", "text"),
    generateTableFilterField(
      "collection_id",
      "Collection",
      "select",
      undefined,
      {
        url: ApiPath.COLLECTIONS,
        valueKey: "id",
        textKey: "name",
      }
    ),
    generateTableFilterField(
      "status",
      "Status",
      "select",
      [
        {
          value: "1",
          text: "Active",
        },
        {
          value: "2",
          text: "Archived",
        },
      ],
      undefined,
      "1"
    ),
  ];
  return (
    <>
      <UpdateProductForm
        open={selectedProduct !== undefined}
        onClose={() => {
          setSelectedProduct(undefined);
          setRequest({
            name: "",
            idr: 0,
            sku: "",
            usd: 0,
            collection_id: -1,
            images: [],
            product_type_id: -1,
            sgd: 0,
            color: "",
            description: "",
            sizes: [
              { size: 36, stock: 0 },
              { size: 37, stock: 0 },
              { size: 38, stock: 0 },
              { size: 39, stock: 0 },
              { size: 40, stock: 0 },
            ],
          });
          dispatch(setLoading(false));
        }}
        item={selectedProduct}
        onChange={handleChangeAddRequest}
        onAddImage={handleAddImage}
        onDeleteImage={handleDeleteImage}
        onAddSize={handleAddSize}
        onDeleteSize={handleDeleteSize}
        onSizeChange={handleSizeChange}
        request={request}
      />
      <CreateProductForm
        open={creatingProduct}
        onClose={() => {
          setCreatingProduct(false);
          dispatch(setLoading(false));
          setRequest({
            name: "",
            idr: 0,
            sku: "",
            usd: 0,
            collection_id: -1,
            product_type_id: -1,
            images: [],
            sgd: 0,
            color: "",
            description: "",
            sizes: [
              { size: 36, stock: 0 },
              { size: 37, stock: 0 },
              { size: 38, stock: 0 },
              { size: 39, stock: 0 },
              { size: 40, stock: 0 },
            ],
          });
        }}
        onChange={handleChangeAddRequest}
        onAddImage={handleAddImage}
        onDeleteImage={handleDeleteImage}
        onAddSize={handleAddSize}
        onDeleteSize={handleDeleteSize}
        onSizeChange={handleSizeChange}
        request={request}
      />
      <Table
        headActions={[
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setCreatingProduct(true)}
          >
            Create Product
          </Button>,
        ]}
        title={"Product"}
        headCells={fields}
        filterFields={filter_fields}
        url={ApiPath.PRODUCT_LIST}
        onRowClick={(e) => {
          dispatch(setLoading(true));
          ProductRepository.GetProductDetail(e.id).then((res) => {
            dispatch(setLoading(false));
            const data = res.data.value;
            setRequest({
              product_type_id: data.product_type_id,
              collection_id: data.collection_id,
              color: data.color,
              description: data.description,
              idr: data.idr,
              images: data.images,
              name: data.name,
              sgd: data.sgd,
              sizes: data.sizes,
              sku: data.sku,
              usd: data.usd,
            });
            setSelectedProduct(data);
          });
        }}
      />
    </>
  );
};

export default ProductPage;
