import { ApiClient } from "../utils/ApiClient";
import { ApiPath } from "../variables/ApiPath";
import { AxiosResponse } from "axios";
import { CreateEventRequest } from "./request/EventRequest";
import moment from "moment";
import { SingleResponse } from "./BasicResponse";

export class EventRepository {
  static CreateEvent = (body: CreateEventRequest): Promise<AxiosResponse> => {
    return ApiClient.Post(ApiPath.CREATE_EVENT, [], {
      ...body,
      start_date: moment(body.start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment(body.end_date).format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  static UpdateEvent = (
    id: number,
    body: CreateEventRequest
  ): Promise<AxiosResponse> => {
    return ApiClient.Put(ApiPath.UPDATE_EVENT, [id.toString()], {
      ...body,
      start_date: moment(body.start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment(body.end_date).format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  static DeleteEvent = (id: number): Promise<AxiosResponse> => {
    return ApiClient.Delete(ApiPath.DELETE_EVENT, [id.toString()]);
  };

  static ShowEvent = (
    id: number
  ): Promise<AxiosResponse<SingleResponse<any>>> => {
    return ApiClient.Get(ApiPath.SHOW_EVENT, [id.toString()], {});
  };
}
