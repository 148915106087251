import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import Input from "../Input";
import { numberToString } from "../../utils/NumberUtils";

interface IProps {
  open: boolean;
  onClose: () => void;
  onChange: (name: string, value: string | number) => void;
  country: string;
  price: number;
  handleUpdate: () => void;
}

const UpdateShippingRateForm = ({
  country,
  price,
  open,
  onClose,
  onChange,
  handleUpdate,
}: IProps) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Update Shipping Rate</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="country"
              label="Country"
              value={country}
              onChange={() => {}}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="price"
              label="Price"
              value={numberToString(price)}
              onChange={(name, value) => {
                onChange(name, value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="primary" onClick={handleUpdate}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateShippingRateForm;
