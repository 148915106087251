import React, { useState } from "react";
import Table from "../components/table/Table";
import { generateHeadCell } from "../models/table/TablePropsGenerator";
import { generateTableFilterField } from "../models/table/FilterProps";
import { useDispatch } from "react-redux";
import { getTableData } from "../redux/actions/TableAction";
import { ApiPath } from "../variables/ApiPath";
import { Button } from "@material-ui/core";
import SnackbarUtils from "../utils/SnackbarUtils";
import { setLoading } from "../redux/actions/LoadingAction";
import { CreateProductTypeRequest } from "../models/request/ProductTypeRequest";
import { ProductTypeResponse } from "../models/response/ProductTypeResponse";
import UpdateProductTypeForm from "../components/productType/UpdateProductTypeForm";
import CreateProductTypeForm from "../components/productType/CreateProductTypeForm";
import { ProductTypeRepository } from "../models/ProductTypeRepository";

const ProductTypePage = () => {
  const dispatch = useDispatch();

  const [createFilter, setCreateFilter] = useState<CreateProductTypeRequest>({
    name: "",
  });

  const [creatingProductType, setCreatingProducType] = useState(false);

  const [selectedProductType, setSelectedProductType] =
    useState<ProductTypeResponse>({
      id: "",
      name: "",
    });

  const [name, setName] = useState("");

  const handleChangeAddRequest = (name: string, value: string | number) => {
    setCreateFilter({
      ...createFilter,
      [name]: value,
    });
  };

  const fields = [generateHeadCell("name", "Name", "text", true)];

  const filter_fields = [generateTableFilterField("name", "Name", "text")];
  return (
    <>
      <UpdateProductTypeForm
        open={selectedProductType.id !== ""}
        onClose={() => {
          setSelectedProductType({
            id: "",
            name: "",
          });
        }}
        onChange={(name, value) => {
          setName(value.toString());
        }}
        name={name}
        handleUpdate={() => {
          dispatch(setLoading(true));
          ProductTypeRepository.updateData(selectedProductType.id, name)
            .then((res) => {
              setSelectedProductType({
                id: "",
                name: "",
              });
              SnackbarUtils.success("Success updating product type");
              dispatch(getTableData());
            })
            .finally(() => {
              dispatch(setLoading(false));
            });
        }}
      />
      <CreateProductTypeForm
        request={createFilter}
        open={creatingProductType}
        onClose={() => {
          setCreatingProducType(false);
          setCreateFilter({
            name: "",
          });
        }}
        onChange={handleChangeAddRequest}
      />
      <Table
        headActions={[
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setCreatingProducType(true)}
          >
            Create Product Type
          </Button>,
        ]}
        title={"Product Type"}
        headCells={fields}
        filterFields={filter_fields}
        onRowClick={(e: ProductTypeResponse) => {
          setSelectedProductType(e);
          setName(e.name);
        }}
        url={ApiPath.GET_PRODUCT_TYPE_LIST}
      />
    </>
  );
};

export default ProductTypePage;
