import LoginPage from "./page/LoginPage";
import ProductPage from "./page/ProductPage";
import CollectionPage from "./page/CollectionPage";
import OrderPage from "./page/OrderPage";
import ShippingRatePage from "./page/ShippingRatePage";
import AccountPage from "./page/AccountPage";
import UserPage from "./page/UserPage";
import CouponPage from "./page/CouponPage";
import ProductTypePage from "./page/ProductTypePage";
import EventPage from "./page/EventPage";
import ColorPage from "./page/ColorPage";
import MessagePage from "./page/MessagePage";
import NewCollectionPage from "./page/NewCollectionPage";

interface NavLinkProps {
  text: string;
  href: string;
}

interface RouteProps {
  url: string;
  component: React.ComponentType;
}

interface IConfig {
  defaultUrl: string;
  projectName: string;
  navLinks: NavLinkProps[];
  routes: RouteProps[];
}

const generateLink = (text: string, href: string) => {
  return { text: text, href: href };
};

const generateRoute = (url: string, component: React.ComponentType) => {
  return { url: url, component: component };
};

export const Config: IConfig = {
  projectName: "GIU Shoes",
  defaultUrl: "/orders",
  navLinks: [
    generateLink("Product", "/products"),
    generateLink("Orders", "/orders"),
    generateLink("Users", "/users"),
    generateLink("Collections", "/collections"),
    generateLink("Shipping Rates", "/shipping-rates"),
    generateLink("Coupons", "/coupons"),
    generateLink("Product Types", "/product-types"),
    generateLink("Events", "/events"),
    generateLink("Colors", "/colors"),
    generateLink("Message", "/message"),
    generateLink("New Collection", "/new-collection"),

  ],
  routes: [
    generateRoute("/login", LoginPage),
    generateRoute("/products", ProductPage),
    generateRoute("/collections", CollectionPage),
    generateRoute("/orders", OrderPage),
    generateRoute("/shipping-rates", ShippingRatePage),
    generateRoute("/account", AccountPage),
    generateRoute("/users", UserPage),
    generateRoute("/coupons", CouponPage),
    generateRoute("/product-types", ProductTypePage),
    generateRoute("/events", EventPage),
    generateRoute("/colors", ColorPage),
    generateRoute("/message", MessagePage),
    generateRoute("/new-collection", NewCollectionPage),
  ],
};
