import { AxiosResponse } from "axios";
import { ApiClient } from "../../utils/ApiClient";
import { ApiPath } from "../../variables/ApiPath";
import { LoginResponse, UserResponse } from "./UserResponse";
import { LoginRequest, UpdateUserRequest } from "./UserRequest";
import { SingleResponse } from "../BasicResponse";
import { AccountRequest } from "../request/AccountRequest";

export class User {
  static login = (
    body: LoginRequest
  ): Promise<AxiosResponse<SingleResponse<LoginResponse>>> => {
    return ApiClient.Post(ApiPath.LOGIN, [], body);
  };

  static getCurrentUser = (): Promise<
    AxiosResponse<SingleResponse<UserResponse>>
  > => {
    return ApiClient.Get(ApiPath.CURRENT_USER, [], {});
  };

  static logout = (): Promise<AxiosResponse> => {
    return ApiClient.Get(ApiPath.LOGOUT, [], {});
  };

  static updateCurrent = (body: AccountRequest): Promise<AxiosResponse> => {
    return ApiClient.Put(ApiPath.UPDATE_ACCOUNT, [], body);
  };

  static updateUser = (
    id: string,
    body: UpdateUserRequest
  ): Promise<AxiosResponse> => {
    return ApiClient.Put(ApiPath.UPDATE_USER, [id], body);
  };

  static activateInactivate = (id: string): Promise<AxiosResponse> => {
    return ApiClient.Delete(ApiPath.UPDATE_USER, [id]);
  };

  static promoteDemote = (id: string): Promise<AxiosResponse> => {
    return ApiClient.Post(ApiPath.PROMOTE_DEMOTE, [id], {});
  };
}
