import { ApiPath } from "../variables/ApiPath";
import { CreateProductRequest } from "./request/ProductRequest";
import { ApiClient } from "../utils/ApiClient";
import { AxiosResponse } from "axios";
import { ProductDetailResponse } from "./response/ProductResponse";
import { PaginationResponse, SingleResponse } from "./BasicResponse";

export class ProductRepository {
  public static CreateProduct = (
    request: CreateProductRequest
  ): Promise<AxiosResponse> => {
    return ApiClient.Post(ApiPath.CREATE_PRODUCT, [], request);
  };

  public static GetAllProducts = (): Promise<
    AxiosResponse<PaginationResponse<any>>
  > => {
    return ApiClient.Post(ApiPath.PRODUCT_LIST, [], {
      filter: {
        name: "",
        sku: "",
        collection_id: "",
        status: 1,
      },
      pagination: {
        page: 0,
        size: 10000,
      },
      sort: {
        order_by: "name",
        direction: "asc",
      },
    });
  };

  public static GetProductDetail = (
    id: string
  ): Promise<AxiosResponse<SingleResponse<ProductDetailResponse>>> => {
    return ApiClient.Get(ApiPath.PRODUCT_DETAIL, [id], {});
  };

  public static UpdateProduct = (
    id: string,
    body: CreateProductRequest
  ): Promise<AxiosResponse> => {
    return ApiClient.Put(ApiPath.UPDATE_PRODUCT, [id], body);
  };

  public static DeleteProduct = (id: string): Promise<AxiosResponse> => {
    return ApiClient.Delete(ApiPath.DELETE_PRODUCT, [id]);
  };
}
