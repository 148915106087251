import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { OrderDetailResponse } from "../models/response/OrderResponse";
import { OrderRepository } from "../models/OrderRepository";
import { numberToString } from "../utils/NumberUtils";

const lodash = require("lodash");

interface IParams {
  id: string;
}

const OrderDetailPage = ({ match }: RouteComponentProps<IParams>) => {
  const [data, setData] = useState<OrderDetailResponse>({
    address: "",
    created_at: "",
    currency: "",
    currency_rate: 0,
    customer_name: "",
    first_name: "",
    grand_total: 0,
    items: [],
    last_name: "",
    order_no: "",
    payment_url: "",
    phone: "",
    shipping_rate: 0,
    status: "",
    total: 0,
    transaction_id: "",
    city: "",
    country: "",
    province: "",
    coupon: "",
    discount: 0,
    dropshipper_name: undefined,
    dropshipper_phone: undefined,
    notes: "",
    proceed_date: undefined,
    postal_code: "",
  });

  useEffect(() => {
    OrderRepository.GetOrderDetail(match.params.id).then((res) => {
      setData(res.data.value);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const paperItem = (title: string, value: string) => {
    return (
      <Paper>
        <Box
          padding="10px"
          height="100px"
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography variant="caption">{title}</Typography>
          {title === "Midtrans ID" ? (
            <Typography variant={"h6"}>
              <a
                href={"https://dashboard.midtrans.com/transactions/" + value}
                target="_blank"
                rel="noreferrer"
              >
                {value}
              </a>
            </Typography>
          ) : (
            <Typography variant="h6">{value}</Typography>
          )}
        </Box>
      </Paper>
    );
  };

  const action = () => {
    return (
      <Paper>
        <Box padding="10px" display="flex">
          {data.status === "Payment Confirmed" ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                OrderRepository.ProcessOrder(data.order_no).then((res) => {
                  window.location.reload();
                });
              }}
            >
              Process Order
            </Button>
          ) : data.status === "Processing Order" ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                OrderRepository.PackOrder(data.order_no).then((res) => {
                  window.location.reload();
                });
              }}
            >
              Pack Order
            </Button>
          ) : data.status === "Packaging Order" ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                OrderRepository.RequestPickupOrder(data.order_no).then(
                  (res) => {
                    window.location.reload();
                  }
                );
              }}
            >
              Request Pickup
            </Button>
          ) : data.status === "Pickup Requested" ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                OrderRepository.SendOrder(data.order_no).then((res) => {
                  window.location.reload();
                });
              }}
            >
              On Delivery
            </Button>
          ) : (
            <div></div>
          )}
          <Button
            variant="outlined"
            style={{ marginLeft: "20px" }}
            color="secondary"
            onClick={() => {
              window.location.href = "/print/" + data.order_no;
            }}
          >
            Print Shipment Info
          </Button>
        </Box>
      </Paper>
    );
  };

  const shippingData = () => {
    return (
      <Paper>
        <Box padding="10px">
          <Table>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6">Shipping Data</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Receiver Name</b>
              </TableCell>
              <TableCell>
                {data.first_name} {data.last_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Receiver Phone</b>
              </TableCell>
              <TableCell>{data.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Country</b>
              </TableCell>
              <TableCell>{data.country}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Province</b>
              </TableCell>
              <TableCell>{data.province}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>City</b>
              </TableCell>
              <TableCell>{data.city}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Address</b>
              </TableCell>
              <TableCell>{data.address}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Postal Code</b>
              </TableCell>
              <TableCell>{data.postal_code}</TableCell>
            </TableRow>
          </Table>
        </Box>
      </Paper>
    );
  };

  const additionalInformation = () => {
    return (
      <Paper>
        <Box padding="10px">
          <Table>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6">Additional Information</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Notes</b>
              </TableCell>
              <TableCell>
                {data.notes === "" ||
                data.notes === null ||
                data.notes === undefined
                  ? "-"
                  : data.notes}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Dropshipper Name</b>
              </TableCell>
              <TableCell>
                {data.dropshipper_name === "" ||
                data.dropshipper_name === null ||
                data.dropshipper_name === undefined
                  ? "-"
                  : data.dropshipper_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Dropshipper Phone</b>
              </TableCell>
              <TableCell>
                {data.dropshipper_phone === "" ||
                data.dropshipper_phone === null ||
                data.dropshipper_phone === undefined
                  ? "-"
                  : data.dropshipper_phone}
              </TableCell>
            </TableRow>
          </Table>
        </Box>
      </Paper>
    );
  };

  const itemData = () => {
    return (
      <Paper>
        <Box padding="10px">
          <Table>
            <TableRow>
              <TableCell colSpan={5}>
                <Typography variant="h6">Items</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              {["Product", "Size", "Quantity", "Price"].map((res) => (
                <TableCell style={{ fontWeight: "bold" }}>{res}</TableCell>
              ))}
            </TableRow>
            {data.items.map((res) => (
              <TableRow>
                <TableCell>
                  <img src={res.image} alt={res.name} height="150px" />
                </TableCell>
                <TableCell>
                  {res.name} in {res.color}
                </TableCell>
                <TableCell>{res.size}</TableCell>
                <TableCell>{res.quantity}</TableCell>
                <TableCell>
                  {data.currency}{" "}
                  {numberToString(
                    res[data.currency.toLowerCase() as "sgd" | "usd" | "idr"]
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                colSpan={4}
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                Subtotal
              </TableCell>
              <TableCell>
                {data.currency}{" "}
                {numberToString(
                  lodash.sum(
                    data.items.map(
                      (i) =>
                        i[data.currency.toLowerCase() as "sgd" | "usd" | "idr"]
                    )
                  )
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={4}
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                Subtotal (IDR)
              </TableCell>
              <TableCell>
                IDR{" "}
                {numberToString(
                  lodash.sum(
                    data.items.map(
                      (i) =>
                        i[data.currency.toLowerCase() as "sgd" | "usd" | "idr"]
                    )
                  ) * data.currency_rate
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={4}
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                Shipping Rate
              </TableCell>
              <TableCell>IDR {numberToString(data.shipping_rate)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={4}
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                Discount {data.coupon !== "-" ? data.coupon : ""}
              </TableCell>
              <TableCell>
                IDR {numberToString(data.discount * data.currency_rate)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={4}
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                Grand Total
              </TableCell>
              <TableCell>IDR {numberToString(data.grand_total)}</TableCell>
            </TableRow>
          </Table>
        </Box>
      </Paper>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={3}>
          {paperItem("Order Number", data.order_no)}
        </Grid>
        <Grid item xs={3}>
          {paperItem(
            "Midtrans ID",
            data.transaction_id === null ? "-" : data.transaction_id
          )}
        </Grid>
        <Grid item xs={3}>
          {paperItem("Customer Name", data.first_name + " " + data.last_name)}
        </Grid>
        <Grid item xs={3}>
          {paperItem("Status", data.status)}
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={4} container spacing={3}>
          <Grid item xs={12}>
            {shippingData()}
          </Grid>
          <Grid item xs={12}>
            {additionalInformation()}
          </Grid>
          {data.status === "Payment Confirmed" ||
          data.status === "Pickup Requested" ||
          data.status === "Processing Order" ||
          data.status === "On Delivery" ||
          data.status === "Packaging Order" ? (
            <Grid item xs={12}>
              {action()}
            </Grid>
          ) : (
            <div></div>
          )}
        </Grid>
        <Grid item xs={8}>
          {itemData()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderDetailPage;
