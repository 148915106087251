class SnackbarUtils {
  snackBar = {
    enqueueSnackbar: (msg: string, finalOptions: any) => {},
    closeSnackbar: (key: any) => {},
  };

  setSnackBar(enqueueSnackbar: any, closeSnackbar: any) {
    this.snackBar.enqueueSnackbar = enqueueSnackbar;
    this.snackBar.closeSnackbar = closeSnackbar;
  }

  success(msg: string, options = {}) {
    return this.toast(msg, { ...options, variant: "success" });
  }
  warning(msg: string, options = {}) {
    return this.toast(msg, { ...options, variant: "warning" });
  }
  info(msg: string, options = {}) {
    return this.toast(msg, { ...options, variant: "info" });
  }

  error(msg: string, options = {}) {
    return this.toast(msg, { ...options, variant: "error" });
  }
  toast(msg: string, options = {}) {
    const finalOptions = {
      variant: "default",
      ...options,
    };
    return this.snackBar.enqueueSnackbar(msg, {
      ...finalOptions,
      autoHideDuration: 5000,
    });
  }
  closeSnackbar(key: any) {
    this.snackBar.closeSnackbar(key);
  }
}

export default new SnackbarUtils();
