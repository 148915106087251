import {
  CreateCouponRequest,
  UpdateCouponRequest,
} from "./request/CouponRequest";
import { ApiClient } from "../utils/ApiClient";
import { ApiPath } from "../variables/ApiPath";
import { AxiosResponse } from "axios";

export class CouponRepository {
  static CreateCoupon = (body: CreateCouponRequest): Promise<AxiosResponse> => {
    return ApiClient.Post(ApiPath.CREATE_COUPON, [], body);
  };

  static DeleteCoupon = (id: string): Promise<AxiosResponse> => {
    return ApiClient.Delete(ApiPath.DELETE_COUPON, [id]);
  };

  static UpdateCoupon = (
    id: string,
    body: UpdateCouponRequest
  ): Promise<AxiosResponse> => {
    return ApiClient.Put(ApiPath.UPDATE_COUPON, [id], body);
  };
}
