import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FilterFieldProps } from "../../models/table/FilterProps";
import { Button, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  getTableData,
  setPagination,
  getTableDataWithFilter,
  getTableDataWithUrlAndFilter,
} from "../../redux/actions/TableAction";
import InputField from "../InputField";

interface IProps {
  fields: FilterFieldProps[];
  url: string;
}

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(2),
    maxWidth: "100%",
  },
}));

const FilterForm = ({ fields, url }: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const f = {} as any;
    fields.forEach((field) => {
      f[field.name] =
        field.initialValue !== undefined ? field.initialValue : "";
    });

    setFilter(f);
    setLoaded(true);
    dispatch(getTableDataWithUrlAndFilter(url, f));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleApplyFilter = (e: any) => {
    e.preventDefault();

    dispatch(getTableDataWithFilter(filter));
  };

  const handleResetFilter = (e: any) => {
    dispatch(setPagination({ page: 0, size: 25 }));
    const f = {} as any;
    fields.forEach((field) => {
      f[field.name] =
        field.initialValue !== undefined ? field.initialValue : "";
    });

    setFilter(f);
    setLoaded(true);
    dispatch(getTableDataWithUrlAndFilter(url, f));
  };

  const handleInputChange = (key: string, value: string) => {
    setFilter({
      ...filter,
      [key]: value,
    });
  };
  return (
    <form
      action=""
      onSubmit={() => {
        dispatch(getTableData());
      }}
    >
      {loaded && (
        <>
          <Grid container spacing={3} className={classes.filterContainer}>
            {fields.map((res) => (
              <Grid item xs={12} md={2}>
                <InputField
                  name={res.name}
                  label={res.label}
                  type={res.type}
                  value={(filter as any)[res.name]}
                  onChange={handleInputChange}
                  options={res.options}
                  async={res.async}
                />
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ paddingLeft: 12, maxWidth: "100%" }}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                onClick={handleApplyFilter}
              >
                Apply
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleResetFilter}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </form>
  );
};

export default FilterForm;
