import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

export interface AsyncProps {
  url: string;
  valueKey: string;
  textKey: string;
}

export interface OptionProps {
  text: string;
  value: number | string;
}

export interface InputFieldProps {
  name: string;
  label: string;
  type:
    | "number"
    | "text"
    | "email"
    | "date"
    | "select"
    | "multiline"
    | "password"
    | "image"
    | "autocomplete"
    | "numeric"
    | "datetime"
    | "multiple-autocomplete";
  value: string | File | string[] | MaterialUiPickersDate;
  options?: OptionProps[];
  async?: AsyncProps;
  onChange: (name: string, value: any) => void;
}

export const generateInputFieldProps = (
  name: string,
  label: string,
  type:
    | "number"
    | "text"
    | "email"
    | "date"
    | "select"
    | "multiline"
    | "image"
    | "password"
    | "autocomplete"
    | "numeric"
    | "datetime"
    | "multiple-autocomplete",
  value: string,
  options?: OptionProps[],
  async?: AsyncProps
) => ({
  name: name,
  label: label,
  type: type,
  value: value,
  options: options,
  async: async,
});
