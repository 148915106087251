import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { generateInputFieldProps } from "../models/InputFieldProps";
import { AccountRequest } from "../models/request/AccountRequest";
import InputField from "../components/InputField";
import { Paper } from "@material-ui/core";
import { User } from "../models/user";
import SnackbarUtils from "../utils/SnackbarUtils";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Giushoes
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AccountPage() {
  const classes = useStyles();

  const [request, setRequest] = useState<AccountRequest>({
    confirmation_password: "",
    name: "",
    new_password: "",
    old_password: "",
  });

  useEffect(() => {
    User.getCurrentUser().then((res) => {
      setRequest({
        name: res.data.value.name,
        confirmation_password: "",
        new_password: "",
        old_password: "",
      });
    });
  }, []);

  const fields = [
    generateInputFieldProps("first_name", "First Name", "text", request.name),
    generateInputFieldProps("last_name", "Last Name", "text", request.name),
    generateInputFieldProps(
      "old_password",
      "Old Password",
      "password",
      request.old_password
    ),
    generateInputFieldProps(
      "new_password",
      "New Password",
      "password",
      request.new_password
    ),
    generateInputFieldProps(
      "confirmation_password",
      "Confirmation Password",
      "password",
      request.confirmation_password
    ),
  ];

  return (
    <Container
      component="main"
      maxWidth="sm"
      style={{
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Paper style={{ padding: "50px" }}>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Update Account
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={(e) => {
              e.preventDefault();

              User.updateCurrent(request).then((res) => {
                SnackbarUtils.success("Success updating account");
                setRequest({
                  ...request,
                  confirmation_password: "",
                  new_password: "",
                  old_password: "",
                });
              });
            }}
          >
            <Grid container spacing={2}>
              {fields.map((res) => (
                <Grid item xs={12}>
                  <InputField
                    name={res.name}
                    label={res.label}
                    type={res.type}
                    value={res.value}
                    onChange={(name, value) => {
                      setRequest({
                        ...request,
                        [name]: value,
                      });
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Update Account
            </Button>
          </form>
        </div>

        <Box mt={5}>
          <Copyright />
        </Box>
      </Paper>
    </Container>
  );
}
