import React, { useState } from "react";
import Table from "../components/table/Table";
import { generateHeadCell } from "../models/table/TablePropsGenerator";
import { generateTableFilterField } from "../models/table/FilterProps";
import { useDispatch } from "react-redux";
import { getTableData } from "../redux/actions/TableAction";
import { ApiPath } from "../variables/ApiPath";
import { Button } from "@material-ui/core";
import CreateCollectionForm from "../components/collection/CreateCollectionForm";
import { CreateCollectionRequest } from "../models/request/CollectionRequest";
import { CollectionResponse } from "../models/response/CollectionResponse";
import UpdateCollectionForm from "../components/collection/UpdateCollectionForm";
import { CollectionRepository } from "../models/CollectionRepository";
import SnackbarUtils from "../utils/SnackbarUtils";
import { setLoading } from "../redux/actions/LoadingAction";

const Collection = () => {
  const dispatch = useDispatch();

  const [createFilter, setCreateFilter] = useState<CreateCollectionRequest>({
    name: "",
    image: "",
  });

  const [creatingCollection, setCreatingCollection] = useState(false);

  const [selectedCollection, setSelectedCollection] =
    useState<CollectionResponse>({
      id: "",
      name: "",
      image: "",
    });

  const handleChangeAddRequest = (name: string, value: string | number) => {
    setCreateFilter({
      ...createFilter,
      [name]: value,
    });
  };

  const fields = [
    generateHeadCell("name", "Name", "text", true),
    generateHeadCell("image", "Image", "image", false),
  ];

  const filter_fields = [generateTableFilterField("name", "Name", "text")];
  return (
    <>
      <UpdateCollectionForm
        open={selectedCollection.id !== ""}
        onClose={() => {
          setSelectedCollection({
            id: "",
            name: "",
            image: "",
          });
        }}
        onChange={(name, value) => {
          setSelectedCollection({
            ...selectedCollection,
            [name]: value,
          });
        }}
        request={selectedCollection}
        handleUpdate={() => {
          dispatch(setLoading(true));
          CollectionRepository.updateData(
            selectedCollection.id,
            selectedCollection
          )
            .then((res) => {
              setSelectedCollection({
                id: "",
                name: "",
                image: "",
              });
              SnackbarUtils.success("Success updating collection");
              dispatch(getTableData());
            })
            .finally(() => {
              dispatch(setLoading(false));
            });
        }}
      />
      <CreateCollectionForm
        request={createFilter}
        open={creatingCollection}
        onClose={() => {
          setCreatingCollection(false);
          setCreateFilter({
            name: "",
            image: "",
          });
        }}
        onChange={handleChangeAddRequest}
      />
      <Table
        headActions={[
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setCreatingCollection(true)}
          >
            Create Collection
          </Button>,
        ]}
        title={"Collection"}
        headCells={fields}
        filterFields={filter_fields}
        onRowClick={(e: CollectionResponse) => {
          setSelectedCollection(e);
        }}
        url={ApiPath.COLLECTION_LIST}
      />
    </>
  );
};

export default Collection;
