import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import SnackbarUtils from "./SnackbarUtils";

const Axios = require("axios");
const client = Axios.create();

const cookies = require("react-cookies");

client.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = cookies.load("ACCESS_TOKEN");
  if (token !== undefined) {
    config.headers.Authorization = "Bearer " + token;
  }

  return config;
});

client.interceptors.response.use(
  (response: AxiosResponse) => {
    SnackbarUtils.closeSnackbar(0);
    return response;
  },
  (error: AxiosError) => {
    try {
      if (
        error.response !== undefined &&
        error.response.data.message.length > 0
      ) {
        SnackbarUtils.error(error.response.data.message);
      } else {
        SnackbarUtils.error(
          "Error " + error.response?.status + ": " + error.response?.statusText
        );
      }
    } catch (exception) {
      SnackbarUtils.error("Unspecified error.");
    }

    return Promise.reject(error);
  }
);

export class ApiClient {
  static Get = (
    url: string,
    pathVariables: string[],
    params: any
  ): Promise<AxiosResponse> => {
    return client?.get(ApiClient.buildUrl(url, pathVariables), {
      params: params,
    });
  };

  static Post = (
    url: string,
    pathVariables: string[],
    body: any
  ): Promise<AxiosResponse> => {
    return client?.post(ApiClient.buildUrl(url, pathVariables), body);
  };

  static Put = (
    url: string,
    pathVariables: string[],
    body: any
  ): Promise<AxiosResponse> => {
    return client?.put(ApiClient.buildUrl(url, pathVariables), body);
  };

  static Delete = (
    url: string,
    pathVariables: string[]
  ): Promise<AxiosResponse> => {
    return client?.delete(ApiClient.buildUrl(url, pathVariables));
  };

  private static buildUrl = (url: string, pathVariables: string[]) => {
    pathVariables.forEach((variable) => {
      url = url.replace("{}", variable);
    });
    return url;
  };
}
