import React, { useState } from "react";
import Table from "../components/table/Table";
import { generateHeadCell } from "../models/table/TablePropsGenerator";
import { generateTableFilterField } from "../models/table/FilterProps";
import { ApiPath } from "../variables/ApiPath";
import { Button } from "@material-ui/core";
import CreateEventForm from "../components/event/CreateEventForm";
import UpdateEventForm from "../components/event/UpdateEventForm";
const EventPage = () => {
  const [selectedId, setSelectedId] = useState(0);

  const [creatingEvent, setCreatingEvent] = useState(false);

  const fields = [
    generateHeadCell("name", "Name", "text", true),
    generateHeadCell("start_date", "Start Date", "text", true),
    generateHeadCell("end_date", "End Date", "text", true),
  ];

  const filter_fields = [generateTableFilterField("name", "Name", "text")];
  return (
    <>
      <UpdateEventForm
        id={selectedId}
        open={selectedId !== 0}
        onClose={() => {
          setSelectedId(0);
        }}
      />
      <CreateEventForm
        open={creatingEvent}
        onClose={() => {
          setCreatingEvent(false);
        }}
      />
      <Table
        headActions={[
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setCreatingEvent(true)}
          >
            Create Event
          </Button>,
        ]}
        title={"Events"}
        headCells={fields}
        filterFields={filter_fields}
        onRowClick={(e) => {
          setSelectedId(e.id);
        }}
        url={ApiPath.GET_EVENTS}
      />
    </>
  );
};

export default EventPage;
