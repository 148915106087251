import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
import SnackbarUtils from "./utils/SnackbarUtils";
import Button from "@material-ui/core/Button";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

import { Provider } from "react-redux";
import store from "./redux/ReduxStore";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#006064",
    },
    secondary: {
      main: "#ef5350",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          action={(key) => {
            return (
              <Button onClick={() => SnackbarUtils.closeSnackbar(key)}>
                Dismiss
              </Button>
            );
          }}
        >
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
