import { ApiPath } from "../variables/ApiPath";
import { ApiClient } from "../utils/ApiClient";
import { AxiosResponse } from "axios";
import { CreateProductTypeRequest } from "./request/ProductTypeRequest";

export class ProductTypeRepository {
  public static createData = (
    body: CreateProductTypeRequest
  ): Promise<AxiosResponse> => {
    return ApiClient.Post(ApiPath.CREATE_PRODUCT_TYPE, [], body);
  };

  public static updateData = (
    id: string,
    name: string
  ): Promise<AxiosResponse> => {
    return ApiClient.Put(ApiPath.UPDATE_PRODUCT_TYPE, [id], {
      name: name,
    });
  };
}
