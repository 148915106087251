export const generateHeadCell = (
  name: string,
  label: string,
  type: "text" | "image" | "number" | "date",
  sortable: boolean
) => {
  return {
    name: name,
    label: label,
    type: type,
    sortable: sortable,
  };
};
