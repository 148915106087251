import { ApiPath } from "../variables/ApiPath";
import { ApiClient } from "../utils/ApiClient";
import { AxiosResponse } from "axios";

export class ShippingRateRepository {
  public static UpdateShippingRate = (
    id: number,
    price: number
  ): Promise<AxiosResponse> => {
    return ApiClient.Put(ApiPath.UPDATE_SHIPPING_RATE, [id.toString()], {
      price: price,
    });
  };
}
