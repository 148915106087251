import React from "react";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import Input from "./Input";
import Select from "./Select";
import { InputFieldProps } from "../models/InputFieldProps";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { v4 as uuidv4 } from "uuid";
import Autocomplete from "./Autocomplete";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import MultipleAutocomplete from "./MultipleAutocomplete";
import { IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { ImageController } from "../models/ImageController";
import { setLoading } from "../redux/actions/LoadingAction";
import { useDispatch } from "react-redux";

const InputField = (props: InputFieldProps) => {
  const dispatch = useDispatch();
  const handleInputChange = (key: string, value: string) => {
    props.onChange(key, value);
  };

  const handleSelectChange = (key: string, value: string) => {
    props.onChange(key, value);
  };

  const handleDateChange = (date: MaterialUiPickersDate, key: string) => {
    props.onChange(key, date);
  };

  const renderDatePicker = (data: InputFieldProps) => {
    return (
      <DatePicker
        clearable
        fullWidth
        label={data.label}
        minDate={new Date(2021, 6, 1)}
        value={data.value}
        onChange={(date) => handleDateChange(date, data.name)}
        animateYearScrolling
      />
    );
  };

  const renderDateTimePicker = (data: InputFieldProps) => {
    return (
      <DateTimePicker
        clearable
        fullWidth
        label={data.label}
        value={data.value}
        onChange={(date) => handleDateChange(date, data.name)}
        animateYearScrolling
      />
    );
  };

  const renderTextField = (data: InputFieldProps) => {
    return (
      <Input
        name={data.name}
        label={data.label}
        value={data.value as string}
        onChange={handleInputChange}
        type={data.type as "number" | "text" | "email" | "password"}
      />
    );
  };

  const renderSelect = (data: InputFieldProps) => {
    return (
      <Select
        async={data.async}
        options={data.options}
        label={data.label}
        name={data.name}
        value={(data.value as any).toString()}
        onChange={handleSelectChange}
      />
    );
  };

  const renderImageField = (data: InputFieldProps) => {
    const id = uuidv4();
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "400px",
            height: "300px",
            position: "relative",
          }}
        >
          {data.value !== undefined && data.value !== "" && (
            <IconButton
              style={{
                zIndex: 100,
                color: "red",
                position: "absolute",
                top: "-25px",
                right: "-25px",
              }}
              onClick={() => {
                props.onChange(props.name, undefined);
              }}
            >
              <CancelIcon />
            </IconButton>
          )}

          <label
            htmlFor={`upload-file-${id}`}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 20,
              border: "1px solid lightgray",
              borderRadius: "5px",
            }}
          />
          {data.value !== undefined && data.value !== "" ? (
            <img
              src={props === null ? "" : (props.value as string)}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                zIndex: 19,
              }}
              alt={"uploaded"}
            />
          ) : (
            <span
              style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                zIndex: 18,
              }}
            >
              <AddAPhotoIcon />
            </span>
          )}

          <input
            accept="image/*"
            type="file"
            hidden
            id={`upload-file-${id}`}
            onChange={(e) => {
              dispatch(setLoading(true));
              if (e.target.files !== null && e.target.value[0] !== undefined) {
                ImageController.Upload(e.target.files[0])
                  .then((res) => {
                    props.onChange(data.name, res.data.value.absolute_path);
                  })
                  .finally(() => {
                    dispatch(setLoading(false));
                  });
              }
            }}
          />
        </div>
      </div>
    );
  };

  const renderAutoComplete = (res: InputFieldProps) => {
    return (
      <Autocomplete
        async={res.async}
        name={res.name}
        label={res.label}
        type="autocomplete"
        value={res.value}
        onChange={res.onChange}
      />
    );
  };

  const renderMultipleAutoComplete = (res: InputFieldProps) => {
    return (
      <MultipleAutocomplete
        async={res.async}
        name={res.name}
        label={res.label}
        type="autocomplete"
        value={res.value}
        onChange={res.onChange}
      />
    );
  };

  const renderInputField = (res: InputFieldProps) => {
    switch (res.type) {
      case "email":
      case "number":
      case "text":
      case "password":
      case "multiline":
      case "numeric":
        return renderTextField(res);
      case "select":
        return renderSelect(res);
      case "date":
        return renderDatePicker(res);
      case "datetime":
        return renderDateTimePicker(res);
      case "autocomplete":
        return renderAutoComplete(res);
      case "multiple-autocomplete":
        return renderMultipleAutoComplete(res);
      case "image":
        return renderImageField(res);
      default:
        return <></>;
    }
  };

  return renderInputField(props);
};

export default InputField;
