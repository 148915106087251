import { ApiPath } from "../variables/ApiPath";
import { ApiClient } from "../utils/ApiClient";
import { AxiosResponse } from "axios";
import {CreateColorRequest} from "./request/ColorRequest";

export class ColorRepository {
  public static createData = (
    body: CreateColorRequest
  ): Promise<AxiosResponse> => {
    return ApiClient.Post(ApiPath.STORE_COLOR, [], body);
  };

  public static deleteColor = (id: number) : Promise<AxiosResponse> => {
    return ApiClient.Delete(ApiPath.DELETE_COLOR, [id.toString()]);
  }
}
