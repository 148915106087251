import { LoadingActionType, SET_LOADING } from "../actions/LoadingAction";

export interface ILoadingReducer {
  loading: boolean;
}

const LoadingReducer = (
  state: ILoadingReducer = {
    loading: false,
  },
  action: LoadingActionType
): ILoadingReducer => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default LoadingReducer;
