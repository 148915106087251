import { UserResponse } from "../../models/user/UserResponse";
import { SET_USER, UserActionTypes } from "../actions/UserAction";

export interface IUserReducer {
  user: UserResponse;
  hasUser: boolean;
}

const UserReducer = (
  state: IUserReducer = {
    user: {
      name: "",
      id: "",
      currency: "",
    },
    hasUser: false,
  },
  action: UserActionTypes
): IUserReducer => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        hasUser: action.payload !== undefined && action.payload !== null,
      };
    default:
      return state;
  }
};

export default UserReducer;
