import React, { useState } from "react";
import Table from "../components/table/Table";
import { generateHeadCell } from "../models/table/TablePropsGenerator";
import { generateTableFilterField } from "../models/table/FilterProps";

import { ApiPath } from "../variables/ApiPath";
import { Button } from "@material-ui/core";
import InputField from "../components/InputField";
import moment from "moment";
import { ExcelController } from "../models/ExcelController";
import { ReduxReducerState } from "../redux/ReduxStore";
import { useDispatch, useSelector } from "react-redux";
import { setTableRowChecked } from "../redux/actions/TableAction";

const OrderPage = () => {
  const [date, setDate] = useState(
    moment(new Date()).format("YYYY-MM-DD HH:mm")
  );
  const dispatch = useDispatch();
  const checked = useSelector<ReduxReducerState, string[]>(
    (state) => state.TableReducer.checkedRows
  );

  const fields = [
    generateHeadCell("order_no", "Order ID", "text", true),
    generateHeadCell("customer", "Customer", "text", true),
    generateHeadCell("country", "Country", "text", true),
    generateHeadCell("coupon", "Coupon", "number", true),
    generateHeadCell("currency", "Currency", "text", true),
    generateHeadCell("discount", "Discount", "number", true),
    generateHeadCell("total", "Total", "number", true),
    generateHeadCell("grand_total", "Grand Total (IDR)", "number", true),
    generateHeadCell("status", "Status", "text", true),
    generateHeadCell("created_at", "Order Date", "date", true),
  ];

  const filter_fields = [
    generateTableFilterField(
      "start_date",
      "Start Date",
      "date",
      undefined,
      undefined,
      null
    ),
    generateTableFilterField(
      "end_date",
      "End Date",
      "date",
      undefined,
      undefined,
      null
    ),
    generateTableFilterField("order_no", "Order Number", "text"),
    generateTableFilterField("customer_name", "Customer Name", "text"),
    generateTableFilterField(
      "country",
      "Country",
      "select",
      [
        {
          text: "Indonesia",
          value: "indonesia",
        },
        {
          text: "Singapore",
          value: "singapore",
        },
      ],
      undefined,
      ""
    ),
    generateTableFilterField(
      "status",
      "Status",
      "select",
      [
        "Waiting for Payment",
        "Payment Confirmed",
        "Processing Order",
        "Packaging Order",
        "On Delivery",
        "Order Complete",
        "Order Expired",
        "Denied",
        "Cancelled",
        "Pickup Requested",
      ].map((res, idx) => ({
        text: res,
        value: idx + 1,
      })),
      undefined,
      ""
    ),
  ];
  return (
    <>
      <Table
        check={{
          key: "order_no",
          checkable: true,
        }}
        checkActions={[
          <InputField
            name={"pickup_date"}
            label={"Pickup Date"}
            type={"datetime"}
            value={date}
            onChange={(e, value) => {
              setDate(value);
            }}
          />,
          <Button
            onClick={() => {
              ExcelController.Download(checked, date).then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "GIU_" + date + "_Loader.csv");
                document.body.appendChild(link);
                link.click();
                dispatch(setTableRowChecked([]));
                setDate(moment(new Date()).format("YYYY-MM-DD"));
              });
            }}
            variant="outlined"
          >
            Pickup
          </Button>,
        ]}
        title={"Order"}
        headCells={fields}
        filterFields={filter_fields}
        onRowClick={(item) => {
          window.location.href = "/orders/" + item.order_no;
        }}
        url={ApiPath.ORDER_LIST}
      />
    </>
  );
};

export default OrderPage;
