import React, { useEffect } from "react";
import { Config } from "../ProjectConfig";

const NotFound = () => {
  useEffect(() => {
    window.location.href = Config.defaultUrl;
  }, []);

  return <div></div>;
};

export default NotFound;
