import { ApiPath } from "../variables/ApiPath";
import { AxiosResponse } from "axios";
import { ApiClient } from "../utils/ApiClient";
import { SingleResponse } from "./BasicResponse";
import { OrderDetailResponse } from "./response/OrderResponse";

export class OrderRepository {
  static GetOrderDetail = (
    id: string
  ): Promise<AxiosResponse<SingleResponse<OrderDetailResponse>>> => {
    return ApiClient.Get(ApiPath.ORDER_DETAIL, [id], {});
  };

  static ProcessOrder = (id: string): Promise<AxiosResponse> => {
    return ApiClient.Post(ApiPath.PROCESS_ORDER, [id], {});
  };
  static PackOrder = (id: string): Promise<AxiosResponse> => {
    return ApiClient.Post(ApiPath.PACK_ORDER, [id], {});
  };
  static RequestPickupOrder = (id: string): Promise<AxiosResponse> => {
    return ApiClient.Post(ApiPath.PICKUP_ORDER, [id], {});
  };
  static SendOrder = (id: string): Promise<AxiosResponse> => {
    return ApiClient.Post(ApiPath.SEND_ORDER, [id], {});
  };
  static CancelOrder = (id: string): Promise<AxiosResponse> => {
    return ApiClient.Post(ApiPath.CANCEL_ORDER, [id], {});
  };
}
