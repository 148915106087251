import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Divider, Grid } from "@material-ui/core";
import { OrderDetailResponse } from "../models/response/OrderResponse";
import { OrderRepository } from "../models/OrderRepository";
import logo from "../assets/logo.png";
import moment from "moment";
var Barcode = require("react-barcode");
interface IParams {
  id: string;
}

const OrderDetailPage = ({ match }: RouteComponentProps<IParams>) => {
  const [data, setData] = useState<OrderDetailResponse>({
    address: "",
    created_at: "",
    currency: "",
    currency_rate: 0,
    customer_name: "",
    first_name: "",
    grand_total: 0,
    items: [],
    last_name: "",
    dropshipper_name: undefined,
    postal_code: "",
    dropshipper_phone: undefined,
    notes: "",
    proceed_date: undefined,
    order_no: "",
    payment_url: "",
    phone: "",
    shipping_rate: 0,
    status: "",
    total: 0,
    transaction_id: "",
    city: "",
    country: "",
    province: "",
    coupon: "",
    discount: 0,
  });

  useEffect(() => {
    OrderRepository.GetOrderDetail(match.params.id).then((res) => {
      setData(res.data.value);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid
      container
      spacing={3}
      style={{
        padding: "20px",
        height: "15cm",
        width: "10cm",
        fontSize: "12px",
      }}
      alignItems="center"
    >
      <Grid item container xs={12} spacing={2} justify="space-between">
        <Grid item>
          <img src={logo} alt="logo" style={{ width: "100px" }} />
        </Grid>
        <Grid item style={{ flexGrow: 1, position: "relative" }}>
          <Grid item xs={12}>
            <p style={{ textAlign: "right" }}>
              <strong>Order ID: #{match.params.id}</strong> <br />
              {data.proceed_date !== null &&
                data.proceed_date !== undefined &&
                moment(new Date(data.proceed_date.toString())).format(
                  "YYYY-MM-DD HH:mm"
                )}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <p style={{ lineHeight: "1rem" }}>
          <strong>SHIP TO:</strong> <br />
          {data.first_name + " " + data.last_name} <br />
          {data.address}
          <br />
          {data.province} {data.postal_code}
          <br />
          {data.country} <br />
          {data.phone}
        </p>
      </Grid>

      <Grid item xs={12}>
        <p style={{ lineHeight: "1rem" }}>
          <strong>FROM:</strong>
          <br />
          {data.dropshipper_name !== undefined &&
          data.dropshipper_name !== null &&
          data.dropshipper_name !== "" ? (
            <>
              {data.dropshipper_name} <br />
              {data.dropshipper_phone} <br />
            </>
          ) : (
            <>
              GIU Shoes ID <br />
              Kompleks Agung Sedayu, <br />
              Harco Mangga Dua blok D no. 2 <br />
              Sawah Besar, Jakarta Pusat 10730 <br />
              Indonesia <br />
              +62 815 1013 6599 <br />
            </>
          )}
        </p>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        justify="center"
        style={{
          display: "flex",
          height: "50px",
          position: "relative",
          margin: "10px 0px",
        }}
      >
        <div
          style={{
            transform: "scale(0.5)",
            position: "absolute",
            top: "-40px",
          }}
        >
          <Barcode value={data.order_no} width={3} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <p
          style={{
            lineHeight: "1rem",
            textAlign: "center",
            color: "#B25E6E",
          }}
        >
          Thank you for shopping with us, GIU Girls! <br />
          <small>
            hello@giushoes.com &nbsp;&nbsp;| &nbsp;&nbsp;giushoes.com
          </small>
        </p>
      </Grid>
    </Grid>
  );
};

export default OrderDetailPage;
