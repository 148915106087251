import { ApiPath } from "../variables/ApiPath";
import { ApiClient } from "../utils/ApiClient";
import { AxiosResponse } from "axios";
import { SingleResponse } from "./BasicResponse";
import { ImageUploadResponse } from "./response/ImageUploadResponse";

export class ImageController {
  public static Upload = (
    image: File
  ): Promise<AxiosResponse<SingleResponse<ImageUploadResponse>>> => {
    const formData = new FormData();
    formData.append("image", image);
    return ApiClient.Post(ApiPath.UPLOAD_IMAGE, [], formData);
  };
}
