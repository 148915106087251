import React, { useState } from "react";
import Table from "../components/table/Table";
import { generateHeadCell } from "../models/table/TablePropsGenerator";
import { generateTableFilterField } from "../models/table/FilterProps";
import { useDispatch } from "react-redux";
import { ApiPath } from "../variables/ApiPath";
import { Button } from "@material-ui/core";
import CreateColorForm from "../components/colors/CreateColorForm";
import { CreateColorRequest } from "../models/request/ColorRequest";
import UpdateColorForm from "../components/colors/UpdateColorForm";
import {ColorResponse} from "../models/response/ColorResponse";
import {ColorRepository} from "../models/ColorRepository";
import {getTableData} from "../redux/actions/TableAction";
import {setLoading} from "../redux/actions/LoadingAction";

const ColorPage = () => {
  const dispatch = useDispatch();

  const [createFilter, setCreateFilter] = useState<CreateColorRequest>({
    name: "",
    code: "",
  });

  const [creatingColor, setCreatingColor] = useState(false);

  const [selectedColor, setSelectedColor] =
    useState<ColorResponse>({
      id: -1,
      name: "",
      code: ""
    });

  const handleChangeAddRequest = (name: string, value: string | number) => {
    setCreateFilter({
      ...createFilter,
      [name]: value,
    });
  };

  const fields = [
    generateHeadCell("name", "Name", "text", true),
  ];

  const filter_fields = [generateTableFilterField("name", "Name", "text")];
  return (
    <>
      <UpdateColorForm
        handleDelete={() => {
            dispatch(setLoading(true))
            ColorRepository.deleteColor(selectedColor.id).then(res => {
                setSelectedColor({
                    id: -1,
                    name: "",
                    code: "",
                })
                dispatch(getTableData());
            }).finally(() => {
                setLoading(false)
            })
        }}
        open={selectedColor.id !== -1}
        onClose={() => {
          setSelectedColor({
            id: -1,
            name: "",
            code: "",
          });
        }}
        onChange={(name, value) => {
          setSelectedColor({
            ...selectedColor,
            [name]: value,
          });
        }}
        request={selectedColor}
      />
      <CreateColorForm
        request={createFilter}
        open={creatingColor}
        onClose={() => {
          setCreatingColor(false);
          setCreateFilter({
            name: "",
            code: "",
          });
        }}
        onChange={handleChangeAddRequest}
      />
      <Table
        headActions={[
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setCreatingColor(true)}
          >
            Create Color
          </Button>,
        ]}
        title={"Color"}
        headCells={fields}
        filterFields={filter_fields}
        onRowClick={(e: ColorResponse) => {
          setSelectedColor(e);
        }}
        url={ApiPath.GET_COLOR}
      />
    </>
  );
};

export default ColorPage;
