import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import Input from "../Input";
import { UpdateUserRequest } from "../../models/user/UserRequest";
import { User } from "../../models/user";
import { UserListResponse } from "../../models/user/UserResponse";
import SnackbarUtils from "../../utils/SnackbarUtils";
import { useDispatch } from "react-redux";
import { getTableData } from "../../redux/actions/TableAction";
import { setLoading } from "../../redux/actions/LoadingAction";

interface IProps {
  open: boolean;
  onClose: () => void;
  onChange: (name: string, value: string | number) => void;
  request: UpdateUserRequest;
  data?: UserListResponse;
}

const UpdateCustomerDialog = ({
  data,
  request,
  open,
  onClose,
  onChange,
}: IProps) => {
  const dispatch = useDispatch();

  const handleUpdateUser = () => {
    if (data !== undefined) {
      dispatch(setLoading(true));
      User.updateUser(data.id, request)
        .then((res) => {
          SnackbarUtils.success("Success updating user.");
          dispatch(getTableData());
          onClose();
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    }
  };

  const handleDemotePromote = () => {
    if (data !== undefined) {
      dispatch(setLoading(true));
      User.promoteDemote(data.id)
        .then((res) => {
          SnackbarUtils.success(
            "Success " +
              (data.role === "Admin" ? "demoting" : "promoting") +
              " user."
          );
          dispatch(getTableData());
          onClose();
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    }
  };

  const handleActiveInactive = () => {
    if (data !== undefined) {
      dispatch(setLoading(true));
      User.activateInactivate(data.id)
        .then((res) => {
          SnackbarUtils.success(
            "Success " +
              (data.status === "Active" ? "deactivating" : "activating") +
              " user."
          );
          dispatch(getTableData());
          onClose();
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Update Customer</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="name"
              label="Name"
              value={request.name}
              onChange={(e, val) => {
                onChange("name", val);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="email"
              label="Email"
              value={request.email}
              onChange={(e, val) => {
                onChange("email", val);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleDemotePromote}
        >
          {data !== undefined && data.role === "Admin" ? "Demote" : "Promote"}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleActiveInactive}
        >
          {data !== undefined && data.status === "Active"
            ? "Deactivate"
            : "Activate"}
        </Button>
        <Button variant="outlined" color="primary" onClick={handleUpdateUser}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCustomerDialog;
