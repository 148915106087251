import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import Input from "../Input";
import InputField from "../InputField";
import {CreateColorRequest} from "../../models/request/ColorRequest";

interface IProps {
  open: boolean;
  onClose: () => void;
  onChange: (name: string, value: string | number) => void;
  request: CreateColorRequest;
  handleDelete: () => void;
}

const UpdateColorForm = ({
  open,
  request,
  onClose,
  onChange,
 handleDelete,
}: IProps) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Update Color</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="name"
              label="Name"
              value={request.name}
              onChange={(name, value) => {}}
            />
          </Grid>

          <Grid item xs={12}>
            <InputField
                name={"code"}
                label={"Hex Code"}
                type={"text"}
                value={request.code}
                onChange={onChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="primary" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateColorForm;
