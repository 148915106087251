import React, { ChangeEvent, ReactElement } from "react";
import {
  Divider,
  Paper,
  TableContainer,
  Toolbar,
  Typography,
  Table as MaterialTable,
  TablePagination,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Checkbox,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FilterForm from "./FilterForm";
import { FilterFieldProps } from "../../models/table/FilterProps";
import { useDispatch, useSelector } from "react-redux";
import { ReduxReducerState } from "../../redux/ReduxStore";
import {
  PaginationProps,
  SortProps,
  TableHeadCell,
} from "../../models/table/TableProps";
import { PaginationResponse } from "../../models/BasicResponse";
import {
  getTableData,
  setPagination,
  setSort,
  setTableRowChecked,
} from "../../redux/actions/TableAction";
import { numberToString } from "../../utils/NumberUtils";
import moment from "moment";

interface CheckProps {
  checkable: boolean;
  key: string;
}

interface IProps {
  title: string;
  filterFields: FilterFieldProps[];
  headCells: TableHeadCell[];
  check?: CheckProps;
  headActions?: ReactElement[];
  checkActions?: ReactElement[];
  onRowClick?: (item: any) => void;
  url: string;
}

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    padding: theme.spacing(2),
  },
  header: {
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  root: {
    width: "100%",
  },
  paper: {
    maxWidth: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
  },
  thumbnail: {
    width: "300px",
    height: "250px",
    objectFit: "cover",
  },
  buttonContainer: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
  },
}));

const Table = ({
  title,
  filterFields,
  headCells,
  check,
  headActions,
  checkActions,
  onRowClick,
  url,
}: IProps) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const checked = useSelector<ReduxReducerState, any[]>((state) => {
    return state.TableReducer.checkedRows;
  });

  const pagination = useSelector<ReduxReducerState, PaginationProps>(
    (state) => state.TableReducer.pagination
  );

  const data = useSelector<ReduxReducerState, PaginationResponse<any>>(
    (state) => state.TableReducer.tableData
  );

  const sort = useSelector<ReduxReducerState, SortProps>(
    (state) => state.TableReducer.sort
  );

  const handleCheck = (id: any) => {
    if (checked.includes(id)) {
      checked.splice(checked.indexOf(id), 1);
    } else {
      checked.push(id);
    }
    dispatch(setTableRowChecked(checked));
  };

  const handleRequestSort = (property: string) => {
    const isAsc = sort.order_by === property && sort.direction === "asc";

    dispatch(
      setSort({
        direction: isAsc ? "desc" : "asc",
        order_by: property,
      })
    );
    dispatch(getTableData());
  };

  const handleChangePage = (event: any, newPage: number) => {
    dispatch(
      setPagination({
        page: newPage,
        size: pagination.size,
      })
    );
    dispatch(getTableData());
  };

  const handleChangeRowPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    dispatch(
      setPagination({
        page: 0,
        size: parseInt(event.target.value, 10),
      })
    );
    dispatch(getTableData());
  };

  const renderToolbar = () => {
    return (
      <Toolbar className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.buttonContainer}>
          {headActions !== undefined && headActions?.map((res) => res)}
        </div>
      </Toolbar>
    );
  };

  const renderTableHead = () => {
    return (
      <TableHead>
        {check !== undefined && checked.length > 0 && (
          <TableRow>
            <TableCell
              colSpan={
                check?.checkable ? headCells.length + 1 : headCells.length
              }
            >
              <Box
                justifyContent={"space-between"}
                display="flex"
                width="100%"
                alignItems="center"
              >
                <span>{checked.length} items checked</span>
                <Box
                  justifyContent="space-betweeen"
                  display="flex"
                  alignItems="center"
                >
                  {checkActions !== undefined &&
                    checkActions?.map((res) => (
                      <Box margin="0px 10px">{res}</Box>
                    ))}
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          {check?.checkable && (
            <TableCell>
              <Checkbox
                checked={data.contents
                  .map((res) => res[check?.key])
                  .every((val) => checked.includes(val))}
                onChange={(e) => {
                  if (
                    data.contents
                      .map((res) => res[check?.key])
                      .every((val) => checked.includes(val))
                  ) {
                    data.contents.forEach((res) => {
                      checked.splice(checked.indexOf(res[check?.key]), 1);
                    });
                  } else {
                    data.contents.forEach((res) => {
                      if (!checked.includes(res[check?.key])) {
                        checked.push(res[check?.key]);
                      }
                    });
                  }
                  dispatch(setTableRowChecked(checked));
                }}
              />
            </TableCell>
          )}
          {headCells.map((headCell) => (
            <TableCell
              style={{ fontWeight: "bold" }}
              key={headCell.name}
              sortDirection={
                sort.order_by === headCell.name ? sort.direction : false
              }
            >
              <TableSortLabel
                active={sort.order_by === headCell.name}
                direction={
                  sort.order_by === headCell.name
                    ? (sort.direction as "asc" | "desc")
                    : "asc"
                }
                onClick={() => handleRequestSort(headCell.name)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const renderTableBody = () => {
    // const rowsPerPage = pagination.size;
    // const emptyRows =
    //   rowsPerPage -
    //   Math.min(
    //     rowsPerPage,
    //     data.contents.length - pagination.page * rowsPerPage
    //   );
    return (
      <TableBody>
        {data.contents.map((row, index) => {
          const cells = [] as any[];
          headCells.forEach((element, idx) => {
            cells.push(
              <TableCell
                align="left"
                onClick={() => {
                  if (title !== "Order" && onRowClick !== undefined) {
                    onRowClick(row);
                  }
                }}
              >
                {title === "Order" && idx === 0 ? (
                  <a
                    href={"/orders/" + row[element.name]}
                    style={{ textDecoration: "underline" }}
                  >
                    {row[element.name]}
                  </a>
                ) : row[element.name] !== null ? (
                  element.type === "image" ? (
                    <img
                      alt={"data"}
                      src={(row as any)[element.name]}
                      className={classes.thumbnail}
                    />
                  ) : element.type === "number" ? (
                    numberToString(row[element.name])
                  ) : element.type === "date" ? (
                    moment(new Date(row[element.name])).format("LLL")
                  ) : (
                    row[element.name]
                  )
                ) : (
                  "-"
                )}
              </TableCell>
            );
          });

          return (
            <TableRow hover>
              {check?.checkable && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={checked.includes(row[check?.key as any])}
                    onChange={(e) => {
                      handleCheck(row[check?.key as any]);
                    }}
                  />
                </TableCell>
              )}
              {cells.map((res) => res)}
            </TableRow>
          );
        })}
        {/*{emptyRows > 0 && (*/}
        {/*  <TableRow style={{ height: 53 * emptyRows }}>*/}
        {/*    <TableCell colSpan={6} />*/}
        {/*  </TableRow>*/}
        {/*)}*/}
      </TableBody>
    );
  };

  return (
    <Paper className={classes.paper}>
      {renderToolbar()}
      <FilterForm fields={filterFields} url={url} />
      <Divider style={{ marginTop: 20 }} />
      <TableContainer>
        <MaterialTable>
          {renderTableHead()}
          {renderTableBody()}
        </MaterialTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.data_count}
        rowsPerPage={pagination.size}
        page={pagination.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowPage}
      />
    </Paper>
  );
};

export default Table;
