import { PaginationResponse } from "../../models/BasicResponse";
import { PaginationProps, SortProps } from "../../models/table/TableProps";
import {
  RESET_FILTER,
  SET_FILTER,
  SET_GET_URL,
  SET_MULTIPLE_ROW_CHECKED,
  SET_PAGINATION,
  SET_SORT,
  SET_TABLE_DATA,
  SET_TABLE_ROW_CHECKED,
  TableActionTypes,
} from "../actions/TableAction";

export interface ITableReducer {
  tableData: PaginationResponse<any>;
  pagination: PaginationProps;
  sort: SortProps;
  getTableDataUrl: string;
  tableFilter: any;
  checkedRows: any[];
}

const initialState: ITableReducer = {
  tableData: {
    data_count: 0,
    total_page: 0,
    contents: [],
  },
  pagination: {
    page: 0,
    size: 25,
  },
  sort: {
    direction: "asc",
    order_by: "",
  },
  tableFilter: {},
  getTableDataUrl: "",
  checkedRows: [],
};

const TableReducer = (
  state: ITableReducer = initialState,
  action: TableActionTypes
) => {
  switch (action.type) {
    case RESET_FILTER:
      return {
        ...state,
        tableFilter: {},
      };
    case SET_SORT:
      return {
        ...state,
        sort: action.payload,
      };
    case SET_FILTER:
      return {
        ...state,
        tableFilter: action.payload,
      };

    case SET_TABLE_DATA:
      return {
        ...state,
        tableData: action.payload,
      };

    case SET_GET_URL:
      return {
        ...state,
        getTableDataUrl: action.payload,
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };
    case SET_TABLE_ROW_CHECKED:
      return {
        ...state,
        checkedRows: [...action.payload],
      };
    case SET_MULTIPLE_ROW_CHECKED:
      return {
        ...state,
        checkedRows: [...action.payload],
      };
    default:
      return state;
  }
};

export default TableReducer;
