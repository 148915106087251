import React, { useState } from "react";
import Table from "../components/table/Table";
import { generateHeadCell } from "../models/table/TablePropsGenerator";
import { generateTableFilterField } from "../models/table/FilterProps";
import UpdateShippingRateForm from "../components/shipping-rate/UpdateShippingRateForm";
import { ShippingRateResponse } from "../models/response/ShippingRateResponse";
import { stringToNumber } from "../utils/NumberUtils";
import { ShippingRateRepository } from "../models/ShippingRateRepository";
import { useDispatch } from "react-redux";
import { getTableData } from "../redux/actions/TableAction";
import { ApiPath } from "../variables/ApiPath";
import SnackbarUtils from "../utils/SnackbarUtils";

const ShippingRatePage = () => {
  const dispatch = useDispatch();

  const filterFields = [generateTableFilterField("country", "Country", "text")];

  const [selectedItem, setSelectedItem] = useState<ShippingRateResponse>({
    id: 0,
    price: 0,
    country: "",
  });

  const [price, setPrice] = useState(0);

  const headCells = [
    generateHeadCell("country", "Country", "text", true),
    generateHeadCell("price", "Price", "number", true),
  ];

  return (
    <>
      <UpdateShippingRateForm
        open={selectedItem.country !== ""}
        onClose={() => {
          setSelectedItem({
            country: "",
            id: 0,
            price: 0,
          });
        }}
        onChange={(name, value) => {
          setPrice(stringToNumber(value.toString()));
        }}
        country={selectedItem.country}
        price={price}
        handleUpdate={() => {
          ShippingRateRepository.UpdateShippingRate(
            selectedItem.id,
            price
          ).then((res) => {
            setSelectedItem({
              country: "",
              id: 0,
              price: 0,
            });
            SnackbarUtils.success("Success updating price");
            dispatch(getTableData());
          });
        }}
      />
      <Table
        title={"Shipping Rate"}
        filterFields={filterFields}
        headCells={headCells}
        onRowClick={(e: ShippingRateResponse) => {
          setSelectedItem(e);
          setPrice(e.price);
        }}
        url={ApiPath.GET_SHIPPING_RATE}
      />
    </>
  );
};

export default ShippingRatePage;
