import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TableCell,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  TableBody,
  Checkbox,
} from "@material-ui/core";
import Input from "../Input";
import SnackbarUtils from "../../utils/SnackbarUtils";
import { useDispatch } from "react-redux";
import { getTableData } from "../../redux/actions/TableAction";
import { setLoading } from "../../redux/actions/LoadingAction";
import InputField from "../InputField";
import {
  CreateEventRequest,
  EventRequestItem,
} from "../../models/request/EventRequest";
import { EventRepository } from "../../models/EventRepository";
import { ProductRepository } from "../../models/ProductRepository";
import moment from "moment";
import { numberToString } from "../../utils/NumberUtils";

interface IProps {
  open: boolean;
  onClose: () => void;
}

const CreateEventForm = ({ open, onClose }: IProps) => {
  const dispatch = useDispatch();

  const [request, setRequest] = useState<CreateEventRequest>({
    end_date: moment(),
    items: [],
    name: "",
    start_date: moment(),
  });
  const [selectedItems, setSelectedItems] = useState<EventRequestItem[]>([]);
  const [selectedOnly, setSelectedOnly] = useState(false);
  const [products, setProducts] = useState([] as any[]);
  const [filterName, setFilterName] = useState("");
  const [discount, setDiscount] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState([] as any[]);

  const onChange = (name: string, value: any) => {
    setRequest({
      ...request,
      [name]: value,
    });
  };

  useEffect(() => {
    if (open) {
      ProductRepository.GetAllProducts().then((res) => {
        setProducts(
          res.data.contents.map((e) => ({
            ...e,
            checked: false,
          }))
        );
        setSelectedItems(
          res.data.contents.map((e) => ({
            product_id: e.id,
            percent: 0,
            checked: false,
          }))
        );
      });
    }
  }, [open]);

  useEffect(() => {
    setFilteredProducts(
      products
        .filter((prod) => {
          let a = true;
          if (selectedOnly) {
            a = selectedItems
              .filter((i) => i.checked)
              .map((i) => i.product_id)
              .includes(prod.id);
          }
          let b = prod.name.toLowerCase().includes(filterName.toLowerCase());
          return a && b;
        })
        .map((i) => i)
    );
  }, [filterName, products, selectedItems, selectedOnly]);

  const reset = () => {
    setRequest({
      end_date: moment(),
      items: [],
      name: "",
      start_date: moment(),
    });
    setSelectedItems([]);
    setFilterName("");
    setFilteredProducts([]);
    setSelectedOnly(false);
  };

  const handleCreateEvent = () => {
    dispatch(setLoading(true));
    EventRepository.CreateEvent({
      ...request,
      items: selectedItems.filter((res) => res.checked),
    })
      .then(() => {
        SnackbarUtils.success("Success creating event.");
        dispatch(getTableData());
        reset();
        onClose();
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <DialogTitle>Create Event</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="name"
              label="Name"
              value={request.name}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="start_date"
              label="Start Date"
              type="datetime"
              value={request.start_date}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="end_date"
              label="End Date"
              type="datetime"
              value={request.end_date}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Grid
                  spacing={2}
                  container
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <Grid item xs={6}>
                    <InputField
                      name="product_name"
                      label="Product Name"
                      type="text"
                      value={filterName}
                      onChange={(name, value) => {
                        setFilterName(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField
                      name="discount"
                      label="Discount"
                      type="number"
                      value={discount.toString()}
                      onChange={(name, value) => {
                        setDiscount(parseInt(value));
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        const filteredIds = filteredProducts.map((e) => e.id);
                        setFilteredProducts(
                          filteredProducts.map((item) => {
                            item.checked = true;
                            return item;
                          })
                        );
                        setSelectedItems(
                          selectedItems.map((item) => {
                            if (filteredIds.includes(item.product_id)) {
                              item.percent = discount;
                              item.checked = true;
                              return item;
                            }
                            return item;
                          })
                        );
                      }}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  variant={selectedOnly ? "contained" : "outlined"}
                  color="primary"
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    setSelectedOnly(!selectedOnly);
                  }}
                >
                  Selected Only
                </Button>
                <span>
                  {selectedItems.filter((res) => res.checked).length} item(s)
                  selected
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer style={{ maxHeight: "440px" }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={
                          filteredProducts.filter((x) => x.checked).length ===
                          filteredProducts.length
                        }
                        onChange={(e) => {
                          if (
                            filteredProducts.filter((x) => x.checked).length <
                            filteredProducts.length
                          ) {
                            const ids = filteredProducts.map((e) => e.id);
                            setFilteredProducts(
                              filteredProducts.map((i) => {
                                const item = i;
                                item.checked = true;
                                return item;
                              })
                            );
                            setSelectedItems(
                              selectedItems.map((i) => {
                                if (ids.includes(i.product_id)) {
                                  const item = i;
                                  item.checked = true;
                                  return item;
                                } else {
                                  return i;
                                }
                              })
                            );
                          } else {
                            const ids = filteredProducts.map((e) => e.id);
                            setFilteredProducts(
                              filteredProducts.map((i) => {
                                const item = i;
                                item.checked = false;
                                return item;
                              })
                            );
                            setSelectedItems(
                              selectedItems.map((i) => {
                                if (ids.includes(i.product_id)) {
                                  const item = i;
                                  item.checked = false;
                                  return item;
                                } else {
                                  return i;
                                }
                              })
                            );
                          }
                        }}
                      />
                    </TableCell>
                    {[
                      "Image",
                      "Name",
                      "Color",
                      "IDR",
                      "SGD",
                      "Discount",
                      "Final IDR",
                      "Final SGD",
                    ].map((res) => (
                      <TableCell key={res}>{res}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedItems.length > 0 &&
                    filteredProducts.map((res: any) => {
                      const discount = selectedItems.filter(
                        (item) => item.product_id === res.id
                      )[0].percent;
                      return (
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              onChange={(e) => {
                                setSelectedItems(
                                  selectedItems.map((i) => {
                                    if (i.product_id === res.id) {
                                      const item = i;
                                      item.checked = e.currentTarget.checked;
                                      return item;
                                    } else {
                                      return i;
                                    }
                                  })
                                );
                                setFilteredProducts(
                                  filteredProducts.map((i) => {
                                    if (i.id === res.id) {
                                      const item = i;
                                      item.checked = e.currentTarget.checked;
                                      return item;
                                    } else {
                                      return i;
                                    }
                                  })
                                );
                              }}
                              checked={
                                selectedItems.filter(
                                  (x) => x.product_id === res.id
                                )[0].checked
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <img
                              alt={"data"}
                              src={res.image}
                              style={{
                                width: "150px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                            />
                          </TableCell>
                          <TableCell>{res.name}</TableCell>
                          <TableCell>{res.color}</TableCell>
                          <TableCell>{numberToString(res.idr)}</TableCell>
                          <TableCell>{numberToString(res.sgd)}</TableCell>
                          <TableCell>
                            <Input
                              name={"percent"}
                              label={""}
                              type="number"
                              value={discount.toString()}
                              onChange={(name, value) => {
                                setSelectedItems(
                                  selectedItems.map((item, idx) => {
                                    if (item.product_id === res.id) {
                                      const newItem = item;
                                      newItem.percent = parseInt(value);
                                      return newItem;
                                    } else {
                                      return item;
                                    }
                                  })
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            {numberToString(
                              discount > 0
                                ? res.idr -
                                    Math.ceil((discount / 100) * res.idr)
                                : res.idr
                            )}
                          </TableCell>
                          <TableCell>
                            {numberToString(
                              discount > 0
                                ? res.sgd -
                                    Math.ceil((discount / 100) * res.sgd)
                                : res.sgd
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            reset();
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button variant="outlined" color="primary" onClick={handleCreateEvent}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEventForm;
