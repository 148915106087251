import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import Input from "../Input";
import SnackbarUtils from "../../utils/SnackbarUtils";
import { useDispatch } from "react-redux";
import { getTableData } from "../../redux/actions/TableAction";
import { setLoading } from "../../redux/actions/LoadingAction";
import { CouponRepository } from "../../models/CouponRepository";
import InputField from "../InputField";
import { CouponResponse } from "../../models/response/CouponResponse";
import moment from "moment";
import { UpdateCouponRequest } from "../../models/request/CouponRequest";
import Swal from "sweetalert2";
interface IProps {
  open: boolean;
  onClose: () => void;
  onChange: (name: string, value: string | number) => void;
  data: CouponResponse;
  request: UpdateCouponRequest;
}

const CouponDetailForm = ({
  open,
  onClose,
  data,
  onChange,
  request,
}: IProps) => {
  const dispatch = useDispatch();

  const handleDeleteCoupon = () => {
    Swal.fire({
      title: "Warning",
      text: "Do you want to delete this coupon?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLoading(true));
        CouponRepository.DeleteCoupon(data.id)
          .then(() => {
            SnackbarUtils.success("Success deleting coupon");
            dispatch(getTableData());
            onClose();
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      }
    });
  };

  const handleUpdateCoupon = () => {
    Swal.fire({
      title: "Warning",
      text: "Do you want to update this coupon?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLoading(true));
        CouponRepository.UpdateCoupon(data.id, request)
          .then(() => {
            SnackbarUtils.success("Success updating coupon");
            dispatch(getTableData());
            onClose();
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      }
    });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>Create Collection</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="code"
              label="Code"
              value={data.code}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="start_time"
              label="Start Date"
              type="date"
              value={moment(new Date(data.start_time))}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="end_time"
              label="End Date"
              type="date"
              value={request.end_time}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="quantity"
              label="Quantity"
              type="number"
              value={data.quantity.toString()}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name="percent"
              label="Percentage"
              type="number"
              value={data.percent.toString()}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              name="idr"
              label="Max IDR Discount"
              type="number"
              value={data.idr.toString()}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              name="sgd"
              label="Max SGD Discount"
              type="number"
              value={data.sgd.toString()}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputField
              name="usd"
              label="Max USD Discount"
              type="number"
              value={data.usd.toString()}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="primary" onClick={handleUpdateCoupon}>
          Update
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDeleteCoupon}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CouponDetailForm;
